import React from 'react';
import { Edit2, Trash2, Star, Phone } from 'lucide-react';
import { MeetingReport } from '../../store/applicationsStore';

interface MeetingReportsListProps {
  reports: MeetingReport[];
  onEdit: (report: MeetingReport) => void;
  onDelete: (reportId: number) => void;
}

export default function MeetingReportsList({
  reports = [],
  onEdit,
  onDelete,
}: MeetingReportsListProps) {
  if (!reports || reports.length === 0) {
    return (
      <div className="text-center text-gray-500 py-8">
        No meeting reports available
      </div>
    );
  }

  const getReportTitle = (type: string) => {
    if (type === 'phone_call') return 'Phone Call';
    return type.split('_').map(word => 
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ');
  };

  const getReportIcon = (type: string) => {
    if (type === 'phone_call') return Phone;
    return Star;
  };

  return (
    <div className="space-y-4">
      {reports.map((report) => {
        const ReportIcon = getReportIcon(report.meetingType);
        return (
          <div
            key={report.id}
            className="bg-white border border-gray-200 rounded-lg p-4 hover:shadow-md transition-shadow"
          >
            <div className="flex justify-between items-start mb-3">
              <div className="flex items-center gap-2">
                <ReportIcon className="w-5 h-5 text-gray-400" />
                <div>
                  <h4 className="text-lg font-semibold text-gray-900">
                    {getReportTitle(report.meetingType)}
                  </h4>
                  <p className="text-sm text-gray-500">
                    {new Date(report.date).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <button
                  onClick={() => onEdit(report)}
                  className="text-blue-600 hover:text-blue-700"
                >
                  <Edit2 size={18} />
                </button>
                <button
                  onClick={() => onDelete(report.id)}
                  className="text-red-600 hover:text-red-700"
                >
                  <Trash2 size={18} />
                </button>
              </div>
            </div>

            <div className="mb-3">
              <div className="flex gap-1">
                {[1, 2, 3, 4, 5].map((rating) => (
                  <Star
                    key={rating}
                    className={`w-4 h-4 ${
                      rating <= report.rating
                        ? 'text-yellow-400 fill-yellow-400'
                        : 'text-gray-300'
                    }`}
                  />
                ))}
              </div>
            </div>

            <div className="mb-3">
              <h5 className="text-sm font-medium text-gray-700 mb-1">Attendees</h5>
              <div className="flex flex-wrap gap-2">
                {report.attendees.map((attendee, index) => (
                  <span
                    key={index}
                    className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                  >
                    {attendee}
                  </span>
                ))}
              </div>
            </div>

            <div>
              <h5 className="text-sm font-medium text-gray-700 mb-1">Notes</h5>
              <p className="text-gray-600 whitespace-pre-wrap">{report.notes}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}