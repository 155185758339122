import React, { useState } from 'react';
import { Plus, Edit2, Trash2, ToggleLeft, ToggleRight } from 'lucide-react';
import useJobsStore from '../../store/jobsStore';
import JobModal from './JobModal';

export default function AdminJobs() {
  const { jobs, toggleJobStatus, deleteJob } = useJobsStore();
  const [selectedJob, setSelectedJob] = useState<number | null>(null);
  const [showNewModal, setShowNewModal] = useState(false);

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold text-gray-900">Job Listings</h1>
        <button
          onClick={() => setShowNewModal(true)}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-blue-700"
        >
          <Plus size={20} />
          Add Job
        </button>
      </div>

      <div className="bg-white shadow rounded-lg">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Title
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Location
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Department
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {jobs.map((job) => (
                <tr key={job.id}>
                  <td className="px-6 py-4 text-sm text-gray-900">{job.title}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{job.location}</td>
                  <td className="px-6 py-4 text-sm text-gray-500">{job.department}</td>
                  <td className="px-6 py-4">
                    <button
                      onClick={() => toggleJobStatus(job.id)}
                      className={`flex items-center gap-2 ${
                        job.isActive ? 'text-green-600' : 'text-gray-400'
                      }`}
                    >
                      {job.isActive ? <ToggleRight size={20} /> : <ToggleLeft size={20} />}
                      {job.isActive ? 'Active' : 'Inactive'}
                    </button>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center gap-4">
                      <button
                        onClick={() => setSelectedJob(job.id)}
                        className="text-blue-600 hover:text-blue-900"
                      >
                        <Edit2 size={18} />
                      </button>
                      <button
                        onClick={() => deleteJob(job.id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        <Trash2 size={18} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <JobModal
        isOpen={showNewModal}
        onClose={() => setShowNewModal(false)}
        mode="create"
      />

      {selectedJob && (
        <JobModal
          isOpen={true}
          onClose={() => setSelectedJob(null)}
          mode="edit"
          jobId={selectedJob}
        />
      )}
    </div>
  );
}