import React from 'react';
import { Edit2, Trash2, CheckCircle } from 'lucide-react';
import { Reminder } from '../../store/applicationsStore';

interface RemindersListProps {
  reminders: Reminder[];
  onEdit: (reminder: Reminder) => void;
  onDelete: (reminderId: number) => void;
  onToggleComplete: (reminderId: number) => void;
}

export default function RemindersList({
  reminders = [],
  onEdit,
  onDelete,
  onToggleComplete,
}: RemindersListProps) {
  if (!reminders || reminders.length === 0) {
    return (
      <div className="text-center text-gray-500 py-8">
        No reminders available
      </div>
    );
  }

  const sortedReminders = [...reminders].sort((a, b) => {
    // Sort by completion status first
    if (a.completed !== b.completed) {
      return a.completed ? 1 : -1;
    }
    // Then sort by due date
    return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
  });

  return (
    <div className="space-y-4">
      {sortedReminders.map((reminder) => {
        const dueDate = new Date(reminder.dueDate);
        const isOverdue = !reminder.completed && dueDate < new Date();

        return (
          <div
            key={reminder.id}
            className={`bg-white border rounded-lg p-4 hover:shadow-md transition-shadow ${
              reminder.completed
                ? 'border-gray-200 bg-gray-50'
                : isOverdue
                ? 'border-red-200 bg-red-50'
                : 'border-blue-200 bg-blue-50'
            }`}
          >
            <div className="flex justify-between items-start">
              <div className="flex-1">
                <div className="flex items-center gap-3 mb-2">
                  <button
                    onClick={() => onToggleComplete(reminder.id)}
                    className={`${
                      reminder.completed
                        ? 'text-green-600'
                        : 'text-gray-400 hover:text-green-600'
                    }`}
                  >
                    <CheckCircle size={20} />
                  </button>
                  <p
                    className={`text-gray-900 ${
                      reminder.completed ? 'line-through text-gray-500' : ''
                    }`}
                  >
                    {reminder.note}
                  </p>
                </div>
                <div className="flex items-center gap-2 text-sm">
                  <span
                    className={`${
                      isOverdue
                        ? 'text-red-600'
                        : reminder.completed
                        ? 'text-gray-500'
                        : 'text-blue-600'
                    }`}
                  >
                    Due: {dueDate.toLocaleDateString()}
                  </span>
                  {isOverdue && (
                    <span className="text-red-600 font-medium">(Overdue)</span>
                  )}
                </div>
              </div>
              <div className="flex items-center gap-2 ml-4">
                <button
                  onClick={() => onEdit(reminder)}
                  className="text-gray-600 hover:text-gray-900"
                >
                  <Edit2 size={18} />
                </button>
                <button
                  onClick={() => onDelete(reminder.id)}
                  className="text-red-600 hover:text-red-700"
                >
                  <Trash2 size={18} />
                </button>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}