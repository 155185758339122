import { useState } from 'react';
import { submitOfferwallApplication } from '../services/offerwallService';
import { getErrorMessage } from '../utils/errorHandling';
import type { OfferwallSubmission } from '../types/offerwall';

export function useOfferwallSubmission() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const submit = async (data: OfferwallSubmission) => {
    try {
      setIsSubmitting(true);
      setError(null);
      await submitOfferwallApplication(data);
      return true;
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      setError(errorMessage);
      return false;
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    submit,
    isSubmitting,
    error,
    clearError: () => setError(null)
  };
}