import React, { useState } from 'react';
import { Edit2, FileText, Plus, Search, X, Phone, Star } from 'lucide-react';
import useApplicationsStore from '../../store/applicationsStore';
import useToCallListStore from '../../store/toCallListStore';
import ApplicantDetailsModal from './ApplicantDetailsModal';
import CVPreviewModal from '../CVPreviewModal';
import NewApplicationModal from './NewApplicationModal';
import EditApplicantModal from './EditApplicantModal';
import { makePhoneCall } from '../../utils/phoneService';

export default function AdminApplicants() {
  const { applications } = useApplicationsStore();
  const { addToList, isInList } = useToCallListStore();
  const [showDetailsModal, setShowDetailsModal] = useState<number | null>(null);
  const [showCVPreview, setShowCVPreview] = useState<{id: number, cv: any} | null>(null);
  const [showNewModal, setShowNewModal] = useState(false);
  const [editingApplicant, setEditingApplicant] = useState<{
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  } | null>(null);
  const [isDialing, setIsDialing] = useState(false);
  const [filters, setFilters] = useState({
    search: '',
    minApplications: '',
    maxApplications: '',
  });

  const uniqueApplicants = applications.reduce((acc, curr) => {
    const key = curr.email || `${curr.firstName}_${curr.lastName}`;
    const existingApplicant = acc.find(a => 
      (a.email && a.email === curr.email) || 
      (!a.email && `${a.firstName}_${a.lastName}` === `${curr.firstName}_${curr.lastName}`)
    );

    if (!existingApplicant) {
      acc.push({
        id: curr.id,
        firstName: curr.firstName,
        lastName: curr.lastName,
        email: curr.email,
        phone: curr.phone,
        cv: curr.cv,
        applicationCount: 1,
        latestApplication: curr.date
      });
    } else {
      existingApplicant.applicationCount++;
      if (new Date(curr.date) > new Date(existingApplicant.latestApplication)) {
        existingApplicant.latestApplication = curr.date;
        if (curr.email && !existingApplicant.email) existingApplicant.email = curr.email;
        if (curr.phone && !existingApplicant.phone) existingApplicant.phone = curr.phone;
        if (curr.cv && !existingApplicant.cv) existingApplicant.cv = curr.cv;
      }
    }
    return acc;
  }, []);

  const filteredApplicants = uniqueApplicants.filter(applicant => {
    const searchTerm = filters.search.toLowerCase();
    const fullName = `${applicant.firstName} ${applicant.lastName}`.toLowerCase();
    const email = (applicant.email || '').toLowerCase();
    const phone = (applicant.phone || '').toLowerCase();

    const matchesSearch = !searchTerm || 
      fullName.includes(searchTerm) || 
      email.includes(searchTerm) || 
      phone.includes(searchTerm);

    const matchesMinApplications = !filters.minApplications || 
      applicant.applicationCount >= parseInt(filters.minApplications);

    const matchesMaxApplications = !filters.maxApplications || 
      applicant.applicationCount <= parseInt(filters.maxApplications);

    return matchesSearch && matchesMinApplications && matchesMaxApplications;
  });

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const clearFilters = () => {
    setFilters({
      search: '',
      minApplications: '',
      maxApplications: '',
    });
  };

  const handleCall = async (phone: string) => {
    try {
      setIsDialing(true);
      await makePhoneCall(phone);
    } catch (error) {
      alert('Failed to initiate phone call');
    } finally {
      setIsDialing(false);
    }
  };

  const handleAddToCallList = (applicant: typeof uniqueApplicants[0]) => {
    addToList({
      applicationId: applicant.id,
      firstName: applicant.firstName,
      lastName: applicant.lastName,
      phone: applicant.phone,
      email: applicant.email,
      notes: '',
      priority: 'medium'
    });
  };

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-semibold text-gray-900">Applicants</h1>
        <button
          onClick={() => setShowNewModal(true)}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-blue-700"
        >
          <Plus size={20} />
          Add Applicant
        </button>
      </div>

      <div className="bg-white p-4 rounded-lg shadow space-y-4">
        <div className="flex items-center justify-between mb-2">
          <h2 className="text-lg font-medium text-gray-900">Filters</h2>
          {(filters.search || filters.minApplications || filters.maxApplications) && (
            <button
              onClick={clearFilters}
              className="text-sm text-gray-500 hover:text-gray-700 flex items-center gap-1"
            >
              <X size={16} />
              Clear filters
            </button>
          )}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="relative">
            <input
              type="text"
              name="search"
              value={filters.search}
              onChange={handleFilterChange}
              placeholder="Search by name, email, or phone"
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
          <div className="flex items-center gap-2">
            <input
              type="number"
              name="minApplications"
              value={filters.minApplications}
              onChange={handleFilterChange}
              placeholder="Min applications"
              min="0"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            <span className="text-gray-500">to</span>
            <input
              type="number"
              name="maxApplications"
              value={filters.maxApplications}
              onChange={handleFilterChange}
              placeholder="Max applications"
              min="0"
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>
      </div>

      <div className="bg-white shadow rounded-lg overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Applicant
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Contact
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Applications
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Latest Activity
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredApplicants.map((applicant) => (
                <tr key={applicant.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">
                      {applicant.firstName} {applicant.lastName}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">{applicant.email || 'No email'}</div>
                    <div className="flex items-center gap-2">
                      <span className="text-sm text-gray-500">{applicant.phone || 'No phone'}</span>
                      {applicant.phone && (
                        <button
                          onClick={() => handleCall(applicant.phone)}
                          disabled={isDialing}
                          className="text-blue-600 hover:text-blue-700 disabled:opacity-50"
                          title="Call via Pushbullet"
                        >
                          <Phone size={16} />
                        </button>
                      )}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className="px-2 py-1 text-xs font-medium rounded-full bg-blue-100 text-blue-800">
                      {applicant.applicationCount} application{applicant.applicationCount !== 1 ? 's' : ''}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {new Date(applicant.latestApplication).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <div className="flex items-center gap-4">
                      {applicant.cv && (
                        <button
                          onClick={() => setShowCVPreview({ id: applicant.id, cv: applicant.cv })}
                          className="text-gray-600 hover:text-gray-900"
                          title="View CV"
                        >
                          <FileText size={18} />
                        </button>
                      )}
                      <button
                        onClick={() => setEditingApplicant(applicant)}
                        className="text-blue-600 hover:text-blue-900"
                        title="Edit Details"
                      >
                        <Edit2 size={18} />
                      </button>
                      {!isInList(applicant.id) && applicant.phone && (
                        <button
                          onClick={() => handleAddToCallList(applicant)}
                          className="text-yellow-600 hover:text-yellow-700"
                          title="Add to Call List"
                        >
                          <Star size={18} />
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
              {filteredApplicants.length === 0 && (
                <tr>
                  <td colSpan={5} className="px-6 py-8 text-center text-gray-500">
                    No applicants found matching the filters
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {showDetailsModal && (
        <ApplicantDetailsModal
          isOpen={true}
          onClose={() => setShowDetailsModal(null)}
          applicationId={showDetailsModal}
        />
      )}

      {showCVPreview && (
        <CVPreviewModal
          isOpen={true}
          onClose={() => setShowCVPreview(null)}
          cv={showCVPreview.cv}
        />
      )}

      {showNewModal && (
        <NewApplicationModal onClose={() => setShowNewModal(false)} />
      )}

      {editingApplicant && (
        <EditApplicantModal
          isOpen={true}
          onClose={() => setEditingApplicant(null)}
          applicant={editingApplicant}
        />
      )}
    </div>
  );
}