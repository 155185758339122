import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface Job {
  id: string;
  title: string;
  companyName: string;
  location: string;
  type: string;
  department: string;
  description: string;
  requirements: string[];
  isActive: boolean;
  createdAt: string;
}

interface JobsState {
  jobs: Job[];
  addJob: (job: Omit<Job, 'id' | 'createdAt' | 'isActive'>) => void;
  toggleJobStatus: (id: string) => void;
  updateJob: (id: string, job: Partial<Job>) => void;
  deleteJob: (id: string) => void;
}

const generateJobId = (companyName: string, title: string): string => {
  const sanitized = `${companyName}-${title}`
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)/g, '');
  return `${sanitized}-${Date.now().toString(36)}`;
};

const useJobsStore = create<JobsState>()(
  persist(
    (set) => ({
      jobs: [],
      addJob: (job) =>
        set((state) => ({
          jobs: [
            ...state.jobs,
            {
              ...job,
              id: generateJobId(job.companyName, job.title),
              isActive: true,
              createdAt: new Date().toISOString(),
            },
          ],
        })),
      toggleJobStatus: (id) =>
        set((state) => ({
          jobs: state.jobs.map((job) =>
            job.id === id ? { ...job, isActive: !job.isActive } : job
          ),
        })),
      updateJob: (id, updatedJob) =>
        set((state) => ({
          jobs: state.jobs.map((job) =>
            job.id === id ? { ...job, ...updatedJob } : job
          ),
        })),
      deleteJob: (id) =>
        set((state) => ({
          jobs: state.jobs.filter((job) => job.id !== id),
        })),
    }),
    {
      name: 'jobs-storage',
    }
  )
);

export default useJobsStore;