import React, { useState } from 'react';
import { Plus, Edit2, Trash2, Users } from 'lucide-react';
import useCompaniesStore from '../../store/companiesStore';
import CompanyModal from './CompanyModal';
import ContactsModal from './ContactsModal';

export default function AdminCompanies() {
  const { companies, deleteCompany } = useCompaniesStore();
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null);
  const [showNewModal, setShowNewModal] = useState(false);
  const [showContactsModal, setShowContactsModal] = useState<string | null>(null);

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold text-gray-900">Companies</h1>
        <button
          onClick={() => setShowNewModal(true)}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-blue-700"
        >
          <Plus size={20} />
          Add Company
        </button>
      </div>

      <div className="bg-white shadow rounded-lg">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Company
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Industry
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Location
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Contacts
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {companies.map((company) => (
                <tr key={company.id}>
                  <td className="px-6 py-4">
                    <div className="text-sm font-medium text-gray-900">
                      {company.name}
                    </div>
                    <div className="text-sm text-gray-500">
                      {company.website}
                    </div>
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {company.industry}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-500">
                    {company.location}
                  </td>
                  <td className="px-6 py-4">
                    <button
                      onClick={() => setShowContactsModal(company.id)}
                      className="text-blue-600 hover:text-blue-900 flex items-center gap-2"
                    >
                      <Users size={16} />
                      {company.contacts.length} Contacts
                    </button>
                  </td>
                  <td className="px-6 py-4">
                    <div className="flex items-center gap-4">
                      <button
                        onClick={() => setSelectedCompany(company.id)}
                        className="text-blue-600 hover:text-blue-900"
                      >
                        <Edit2 size={18} />
                      </button>
                      <button
                        onClick={() => deleteCompany(company.id)}
                        className="text-red-600 hover:text-red-900"
                      >
                        <Trash2 size={18} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <CompanyModal
        isOpen={showNewModal}
        onClose={() => setShowNewModal(false)}
        mode="create"
      />

      {selectedCompany && (
        <CompanyModal
          isOpen={true}
          onClose={() => setSelectedCompany(null)}
          mode="edit"
          companyId={selectedCompany}
        />
      )}

      {showContactsModal && (
        <ContactsModal
          isOpen={true}
          onClose={() => setShowContactsModal(null)}
          companyId={showContactsModal}
        />
      )}
    </div>
  );
}