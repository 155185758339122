import React from 'react';
import { useNavigate } from 'react-router-dom';
import OfferwallStepper from '../components/offerwall/OfferwallStepper';
import useOfferwallStore from '../store/offerwallStore';

export default function CompanyOfferwall() {
  const navigate = useNavigate();
  const { resetForm } = useOfferwallStore();

  React.useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-white py-12">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-2xl shadow-xl p-8 sm:p-12">
          <OfferwallStepper />
        </div>
      </div>
    </div>
  );
}