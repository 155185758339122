import { motion, AnimatePresence } from 'framer-motion';
import { X, Download } from 'lucide-react';
import { downloadCV } from '../utils/fileUtils';

interface CVPreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  cv: {
    name: string;
    data: string;
    type: string;
  };
}

export default function CVPreviewModal({ isOpen, onClose, cv }: CVPreviewModalProps) {
  const handleDownload = () => {
    downloadCV(cv);
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="fixed inset-0 bg-black/50 z-50"
          />
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="fixed inset-0 z-50 flex items-center justify-center p-4"
          >
            <div className="bg-white rounded-xl shadow-xl w-full max-w-4xl h-[90vh] flex flex-col">
              <div className="p-4 border-b flex justify-between items-center">
                <h2 className="text-xl font-semibold">{cv.name}</h2>
                <div className="flex items-center gap-2">
                  <button
                    onClick={handleDownload}
                    className="flex items-center gap-2 px-4 py-2 text-blue-600 hover:text-blue-700"
                  >
                    <Download size={20} />
                    Download
                  </button>
                  <button
                    onClick={onClose}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <X size={24} />
                  </button>
                </div>
              </div>
              <div className="flex-1 p-4 overflow-auto">
                <iframe
                  src={cv.data}
                  className="w-full h-full"
                  title="CV Preview"
                />
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}