import React from 'react';
import { Plus, Minus } from 'lucide-react';
import useOfferwallStore, { EmploymentType } from '../../../store/offerwallStore';

const employmentTypes = [
  { id: 'full_time' as EmploymentType, label: 'Tam Zamanlı' },
  { id: 'freelance' as EmploymentType, label: 'Freelance' },
  { id: 'hybrid' as EmploymentType, label: 'Hibrit/Uzaktan' },
];

export default function PositionStep() {
  const { formData, updatePositions, nextStep, prevStep } = useOfferwallStore();
  const [positions, setPositions] = React.useState(formData.positions);

  const handlePositionCount = (increment: boolean) => {
    setPositions((prev) => ({
      ...prev,
      count: increment ? prev.count + 1 : Math.max(1, prev.count - 1),
    }));
  };

  const handlePositionTypes = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const types = e.target.value.split('\n').filter(Boolean);
    setPositions((prev) => ({ ...prev, types }));
  };

  const toggleEmploymentType = (type: EmploymentType) => {
    setPositions((prev) => ({
      ...prev,
      employmentType: prev.employmentType.includes(type)
        ? prev.employmentType.filter((t) => t !== type)
        : [...prev.employmentType, type],
    }));
  };

  const handleNext = () => {
    if (positions.types.length > 0 && positions.employmentType.length > 0) {
      updatePositions(positions);
      nextStep();
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-900 mb-4">
        Açık Pozisyonlar Hakkında Daha Fazla Bilgi Verin
      </h2>

      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Açık Pozisyon Sayısı
          </label>
          <div className="flex items-center space-x-4">
            <button
              onClick={() => handlePositionCount(false)}
              className="w-10 h-10 rounded-full border-2 border-gray-300 flex items-center justify-center hover:border-blue-500"
            >
              <Minus className="w-5 h-5" />
            </button>
            <span className="text-xl font-medium w-12 text-center">
              {positions.count}
            </span>
            <button
              onClick={() => handlePositionCount(true)}
              className="w-10 h-10 rounded-full border-2 border-gray-300 flex items-center justify-center hover:border-blue-500"
            >
              <Plus className="w-5 h-5" />
            </button>
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Aranan Pozisyon Türleri
          </label>
          <textarea
            value={positions.types.join('\n')}
            onChange={handlePositionTypes}
            placeholder="Her satıra bir pozisyon yazın&#10;Örnek:&#10;Yazılım Geliştirici&#10;Proje Yöneticisi"
            className="w-full h-32 px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            İş Tipi
          </label>
          <div className="space-y-2">
            {employmentTypes.map((type) => (
              <button
                key={type.id}
                onClick={() => toggleEmploymentType(type.id)}
                className={`w-full text-left px-4 py-3 rounded-lg border-2 transition-colors ${
                  positions.employmentType.includes(type.id)
                    ? 'border-blue-600 bg-blue-50'
                    : 'border-gray-200 hover:border-blue-200'
                }`}
              >
                {type.label}
              </button>
            ))}
          </div>
        </div>

        <div className="flex justify-between pt-4">
          <button
            onClick={prevStep}
            className="px-6 py-2 text-gray-600 hover:text-gray-900"
          >
            Geri
          </button>
          <button
            onClick={handleNext}
            disabled={positions.types.length === 0 || positions.employmentType.length === 0}
            className="px-6 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Devam Et
          </button>
        </div>
      </div>
    </div>
  );
}