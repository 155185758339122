import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MainSite from './pages/MainSite';
import AdminLogin from './pages/AdminLogin';
import AdminPanel from './pages/AdminPanel';
import OnePager from './pages/OnePager';
import ResetPassword from './pages/ResetPassword';
import UpdatePassword from './pages/UpdatePassword';
import CompanyOfferwall from './pages/CompanyOfferwall';
import ProtectedRoute from './components/ProtectedRoute';
import useAuthStore from './store/authStore';

function App() {
  const { initialize } = useAuthStore();

  React.useEffect(() => {
    initialize();
  }, [initialize]);

  return (
    <Routes>
      <Route path="/" element={<MainSite />} />
      <Route path="/one-pager" element={<Navigate to="/one-pager/tr" replace />} />
      <Route path="/one-pager/:lang" element={<OnePager />} />
      <Route path="/company" element={<CompanyOfferwall />} />
      <Route path="/admin/login" element={<AdminLogin />} />
      <Route path="/admin/reset-password" element={<ResetPassword />} />
      <Route path="/update-password" element={<UpdatePassword />} />
      <Route
        path="/admin/*"
        element={
          <ProtectedRoute>
            <AdminPanel />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default App;