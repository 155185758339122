import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export type ApplicationStatus = 
  | 'new'
  | 'accepted'
  | 'rejected'
  | 'first_meeting'
  | 'second_meeting'
  | 'third_meeting'
  | 'fourth_meeting'
  | 'allianz_meeting'
  | 'phone_call';

export interface StatusUpdate {
  id: number;
  status: ApplicationStatus;
  comment: string;
  date: string;
}

export interface MeetingReport {
  id: number;
  date: string;
  meetingType: ApplicationStatus;
  notes: string;
  rating: number;
  attendees: string[];
  createdAt: string;
}

export interface Reminder {
  id: number;
  note: string;
  dueDate: string;
  completed: boolean;
  createdAt: string;
}

export interface Application {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  position: string;
  jobId: string;
  coverLetter: string;
  notes?: string;
  currentStatus: ApplicationStatus;
  statusHistory: StatusUpdate[];
  meetingReports: MeetingReport[];
  reminders: Reminder[];
  date: string;
  cv?: {
    name: string;
    data: string;
    type: string;
  };
}

interface ApplicationsState {
  applications: Application[];
  addApplication: (application: Omit<Application, 'id' | 'currentStatus' | 'statusHistory' | 'meetingReports' | 'reminders' | 'date'>) => void;
  updateApplication: (id: number, updates: Partial<Application>) => void;
  updateStatus: (id: number, status: ApplicationStatus, comment: string) => void;
  deleteApplication: (id: number) => void;
  addMeetingReport: (id: number, report: Omit<MeetingReport, 'id' | 'createdAt'>) => void;
  updateMeetingReport: (applicationId: number, reportId: number, report: Partial<MeetingReport>) => void;
  deleteMeetingReport: (applicationId: number, reportId: number) => void;
  addReminder: (id: number, reminder: Omit<Reminder, 'id' | 'completed' | 'createdAt'>) => void;
  updateReminder: (applicationId: number, reminderId: number, reminder: Partial<Reminder>) => void;
  deleteReminder: (applicationId: number, reminderId: number) => void;
  toggleReminderComplete: (applicationId: number, reminderId: number) => void;
}

const useApplicationsStore = create<ApplicationsState>()(
  persist(
    (set) => ({
      applications: [],
      addApplication: (application) =>
        set((state) => ({
          applications: [
            ...state.applications,
            {
              ...application,
              id: Date.now(),
              currentStatus: 'new',
              statusHistory: [{
                id: Date.now(),
                status: 'new',
                comment: 'Application received',
                date: new Date().toISOString(),
              }],
              meetingReports: [],
              reminders: [],
              date: new Date().toISOString(),
            },
          ],
        })),
      updateApplication: (id, updates) =>
        set((state) => ({
          applications: state.applications.map((app) =>
            app.id === id ? { ...app, ...updates } : app
          ),
        })),
      updateStatus: (id, status, comment) =>
        set((state) => ({
          applications: state.applications.map((app) =>
            app.id === id
              ? {
                  ...app,
                  currentStatus: status,
                  statusHistory: [
                    ...app.statusHistory,
                    {
                      id: Date.now(),
                      status,
                      comment,
                      date: new Date().toISOString(),
                    },
                  ],
                }
              : app
          ),
        })),
      deleteApplication: (id) =>
        set((state) => ({
          applications: state.applications.filter((app) => app.id !== id),
        })),
      addMeetingReport: (id, report) =>
        set((state) => ({
          applications: state.applications.map((app) =>
            app.id === id
              ? {
                  ...app,
                  meetingReports: [
                    ...(app.meetingReports || []),
                    {
                      ...report,
                      id: Date.now(),
                      createdAt: new Date().toISOString(),
                    },
                  ],
                }
              : app
          ),
        })),
      updateMeetingReport: (applicationId, reportId, updatedReport) =>
        set((state) => ({
          applications: state.applications.map((app) =>
            app.id === applicationId
              ? {
                  ...app,
                  meetingReports: (app.meetingReports || []).map((report) =>
                    report.id === reportId
                      ? { ...report, ...updatedReport }
                      : report
                  ),
                }
              : app
          ),
        })),
      deleteMeetingReport: (applicationId, reportId) =>
        set((state) => ({
          applications: state.applications.map((app) =>
            app.id === applicationId
              ? {
                  ...app,
                  meetingReports: (app.meetingReports || []).filter(
                    (report) => report.id !== reportId
                  ),
                }
              : app
          ),
        })),
      addReminder: (id, reminder) =>
        set((state) => ({
          applications: state.applications.map((app) =>
            app.id === id
              ? {
                  ...app,
                  reminders: [
                    ...(app.reminders || []),
                    {
                      ...reminder,
                      id: Date.now(),
                      completed: false,
                      createdAt: new Date().toISOString(),
                    },
                  ],
                }
              : app
          ),
        })),
      updateReminder: (applicationId, reminderId, updatedReminder) =>
        set((state) => ({
          applications: state.applications.map((app) =>
            app.id === applicationId
              ? {
                  ...app,
                  reminders: (app.reminders || []).map((reminder) =>
                    reminder.id === reminderId
                      ? { ...reminder, ...updatedReminder }
                      : reminder
                  ),
                }
              : app
          ),
        })),
      deleteReminder: (applicationId, reminderId) =>
        set((state) => ({
          applications: state.applications.map((app) =>
            app.id === applicationId
              ? {
                  ...app,
                  reminders: (app.reminders || []).filter(
                    (reminder) => reminder.id !== reminderId
                  ),
                }
              : app
          ),
        })),
      toggleReminderComplete: (applicationId, reminderId) =>
        set((state) => ({
          applications: state.applications.map((app) =>
            app.id === applicationId
              ? {
                  ...app,
                  reminders: (app.reminders || []).map((reminder) =>
                    reminder.id === reminderId
                      ? { ...reminder, completed: !reminder.completed }
                      : reminder
                  ),
                }
              : app
          ),
        })),
    }),
    {
      name: 'applications-storage',
    }
  )
);

export default useApplicationsStore;