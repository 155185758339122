import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface ToCallItem {
  id: number;
  applicationId: number;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  position: string;
  company: string;
  notes: string;
  addedAt: string;
  priority: 'high' | 'medium' | 'low';
}

interface ToCallListState {
  items: ToCallItem[];
  addToList: (item: Omit<ToCallItem, 'id' | 'addedAt'>) => void;
  removeFromList: (id: number) => void;
  updateNotes: (id: number, notes: string) => void;
  updatePriority: (id: number, priority: ToCallItem['priority']) => void;
  isInList: (applicationId: number) => boolean;
}

const useToCallListStore = create<ToCallListState>()(
  persist(
    (set, get) => ({
      items: [],
      addToList: (item) =>
        set((state) => ({
          items: [
            ...state.items,
            {
              ...item,
              id: Date.now(),
              addedAt: new Date().toISOString(),
            },
          ],
        })),
      removeFromList: (id) =>
        set((state) => ({
          items: state.items.filter((item) => item.id !== id),
        })),
      updateNotes: (id, notes) =>
        set((state) => ({
          items: state.items.map((item) =>
            item.id === id ? { ...item, notes } : item
          ),
        })),
      updatePriority: (id, priority) =>
        set((state) => ({
          items: state.items.map((item) =>
            item.id === id ? { ...item, priority } : item
          ),
        })),
      isInList: (applicationId) =>
        get().items.some((item) => item.applicationId === applicationId),
    }),
    {
      name: 'to-call-list-storage',
    }
  )
);

export default useToCallListStore;