import React from 'react';
import { CheckCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function SuccessStep() {
  const navigate = useNavigate();

  return (
    <div className="text-center py-8">
      <div className="flex justify-center mb-6">
        <CheckCircle className="w-16 h-16 text-green-500" />
      </div>
      <h2 className="text-2xl font-bold text-gray-900 mb-4">
        Teşekkürler!
      </h2>
      <p className="text-gray-600 mb-8">
        Başvurunuz başarıyla alındı. En kısa sürede sizinle iletişime geçeceğiz.
      </p>
      <button
        onClick={() => navigate('/')}
        className="px-6 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors"
      >
        Ana Sayfaya Dön
      </button>
    </div>
  );
}