import React, { useState } from 'react';
import { X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import useApplicationsStore, { ApplicationStatus } from '../../store/applicationsStore';

interface ApplicationStatusModalProps {
  applicationId: number;
  onClose: () => void;
}

const statusOptions: { value: ApplicationStatus; label: string }[] = [
  { value: 'new', label: 'New' },
  { value: 'accepted', label: 'Accepted' },
  { value: 'rejected', label: 'Rejected' },
  { value: 'first_meeting', label: 'First Meeting' },
  { value: 'second_meeting', label: 'Second Meeting' },
  { value: 'third_meeting', label: 'Third Meeting' },
  { value: 'fourth_meeting', label: 'Fourth Meeting' },
  { value: 'allianz_meeting', label: 'Allianz Meeting' },
];

export default function ApplicationStatusModal({
  applicationId,
  onClose,
}: ApplicationStatusModalProps) {
  const [status, setStatus] = useState<ApplicationStatus>('new');
  const [comment, setComment] = useState('');
  const updateStatus = useApplicationsStore((state) => state.updateStatus);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    updateStatus(applicationId, status, comment);
    onClose();
  };

  return (
    <AnimatePresence>
      {true && (
        <>
          <motion.div
            key="overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="fixed inset-0 bg-black/50 z-50"
          />
          <motion.div
            key="modal"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="fixed inset-0 z-50 flex items-center justify-center p-4"
          >
            <div className="bg-white rounded-xl shadow-xl max-w-md w-full">
              <div className="p-6">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-2xl font-bold text-gray-900">Update Status</h2>
                  <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                    <X size={24} />
                  </button>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        New Status
                      </label>
                      <select
                        value={status}
                        onChange={(e) => setStatus(e.target.value as ApplicationStatus)}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      >
                        {statusOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Comment
                      </label>
                      <textarea
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        required
                        rows={4}
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Add details about this status update..."
                      />
                    </div>
                    <button
                      type="submit"
                      className="w-full bg-blue-600 text-white px-6 py-3 rounded-full hover:bg-blue-700 transition-colors"
                    >
                      Update Status
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}