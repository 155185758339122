import React from 'react';
import useOfferwallStore from '../../store/offerwallStore';
import ProgressBar from './ProgressBar';
import StepContainer from './StepContainer';
import WelcomeStep from './steps/WelcomeStep';
import NeedsStep from './steps/NeedsStep';
import PositionStep from './steps/PositionStep';
import SolutionStep from './steps/SolutionStep';
import ContactStep from './steps/ContactStep';
import SuccessStep from './steps/SuccessStep';

const steps = [
  { id: 1, component: WelcomeStep },
  { id: 2, component: NeedsStep },
  { id: 3, component: PositionStep },
  { id: 4, component: SolutionStep },
  { id: 5, component: ContactStep },
  { id: 6, component: SuccessStep },
];

export default function OfferwallStepper() {
  const { currentStep } = useOfferwallStore();
  const CurrentStepComponent = steps[currentStep - 1].component;

  return (
    <div>
      {currentStep !== 1 && (
        <div className="mb-12">
          <ProgressBar currentStep={currentStep} />
        </div>
      )}

      <StepContainer step={currentStep}>
        <CurrentStepComponent />
      </StepContainer>
    </div>
  );
}