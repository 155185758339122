import React, { useState } from 'react';
import { Plus, ChevronDown, ChevronUp, User, FileText, Upload, Phone, Star } from 'lucide-react';
import useApplicationsStore, { ApplicationStatus } from '../../store/applicationsStore';
import useJobsStore from '../../store/jobsStore';
import useToCallListStore from '../../store/toCallListStore';
import ApplicationStatusModal from './ApplicationStatusModal';
import NewApplicationModal from './NewApplicationModal';
import ApplicantDetailsModal from './ApplicantDetailsModal';
import BulkUploadModal from './BulkUploadModal';
import { makePhoneCall } from '../../utils/phoneService';

const statusLabels: Record<ApplicationStatus, string> = {
  new: 'New',
  accepted: 'Accepted',
  rejected: 'Rejected',
  first_meeting: 'First Meeting',
  second_meeting: 'Second Meeting',
  third_meeting: 'Third Meeting',
  fourth_meeting: 'Fourth Meeting',
  allianz_meeting: 'Allianz Meeting',
};

export default function AdminApplications() {
  const { applications } = useApplicationsStore();
  const { jobs } = useJobsStore();
  const { addToList, isInList } = useToCallListStore();
  const [selectedApplication, setSelectedApplication] = useState<number | null>(null);
  const [showNewModal, setShowNewModal] = useState(false);
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState<number | null>(null);
  const [statusFilter, setStatusFilter] = useState<ApplicationStatus | 'all'>('all');
  const [companyFilter, setCompanyFilter] = useState('all');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [isDialing, setIsDialing] = useState(false);

  const uniqueCompanies = Array.from(
    new Set(jobs.map(job => ({ id: job.id, name: job.companyName })))
  ).sort((a, b) => a.name.localeCompare(b.name));

  const filteredApplications = applications.filter(app => {
    const job = jobs.find(j => j.id === app.jobId);
    const appDate = new Date(app.date);
    
    const matchesStatus = statusFilter === 'all' || app.currentStatus === statusFilter;
    const matchesCompany = companyFilter === 'all' || job?.companyName === companyFilter;
    const matchesStartDate = !startDate || appDate >= new Date(startDate);
    const matchesEndDate = !endDate || appDate <= new Date(endDate);

    return matchesStatus && matchesCompany && matchesStartDate && matchesEndDate;
  }).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  const toggleExpand = (id: number) => {
    setExpandedRows(prev =>
      prev.includes(id) ? prev.filter(rowId => rowId !== id) : [...prev, id]
    );
  };

  const handleCall = async (phone: string) => {
    try {
      setIsDialing(true);
      await makePhoneCall(phone);
    } catch (error) {
      alert('Failed to initiate phone call');
    } finally {
      setIsDialing(false);
    }
  };

  const handleAddToCallList = (application: typeof applications[0]) => {
    const job = jobs.find(j => j.id === application.jobId);
    if (!job) return;

    addToList({
      applicationId: application.id,
      firstName: application.firstName,
      lastName: application.lastName,
      phone: application.phone,
      email: application.email,
      position: job.title,
      company: job.companyName,
      notes: '',
      priority: 'medium'
    });
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-semibold text-gray-900">Job Applications</h1>
        <div className="flex gap-4">
          <button
            onClick={() => setShowBulkUploadModal(true)}
            className="bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-blue-700"
          >
            <Upload size={20} />
            Bulk Upload
          </button>
          <button
            onClick={() => setShowNewModal(true)}
            className="bg-blue-600 text-white px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-blue-700"
          >
            <Plus size={20} />
            Add Application
          </button>
        </div>
      </div>

      <div className="mb-6 grid grid-cols-4 gap-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Status
          </label>
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value as ApplicationStatus | 'all')}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="all">All Status</option>
            {Object.entries(statusLabels).map(([value, label]) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Company
          </label>
          <select
            value={companyFilter}
            onChange={(e) => setCompanyFilter(e.target.value)}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          >
            <option value="all">All Companies</option>
            {uniqueCompanies.map((company) => (
              <option key={company.id} value={company.name}>
                {company.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Start Date
          </label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            End Date
          </label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>
      </div>

      <div className="bg-white shadow rounded-lg">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Expand
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Applicant
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Position
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Company
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Current Status
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredApplications.map((application) => {
                const job = jobs.find(j => j.id === application.jobId);
                return (
                  <React.Fragment key={application.id}>
                    <tr>
                      <td className="px-6 py-4">
                        <button
                          onClick={() => toggleExpand(application.id)}
                          className="text-gray-500 hover:text-gray-700"
                        >
                          {expandedRows.includes(application.id) ? (
                            <ChevronUp size={20} />
                          ) : (
                            <ChevronDown size={20} />
                          )}
                        </button>
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex items-center gap-3">
                          <User className="h-5 w-5 text-gray-400" />
                          <div>
                            <div className="text-sm font-medium text-gray-900">
                              {application.firstName} {application.lastName}
                            </div>
                            <div className="text-sm text-gray-500">{application.email}</div>
                            <div className="text-sm text-gray-500 flex items-center gap-2">
                              {application.phone}
                              {application.phone && (
                                <button
                                  onClick={() => handleCall(application.phone)}
                                  disabled={isDialing}
                                  className="text-blue-600 hover:text-blue-700 disabled:opacity-50"
                                  title="Call"
                                >
                                  <Phone size={16} />
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900">
                        {job?.title}
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-900">
                        {job?.companyName}
                      </td>
                      <td className="px-6 py-4">
                        <span
                          className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                            application.currentStatus === 'new'
                              ? 'bg-blue-100 text-blue-800'
                              : application.currentStatus === 'accepted'
                              ? 'bg-green-100 text-green-800'
                              : application.currentStatus === 'rejected'
                              ? 'bg-red-100 text-red-800'
                              : 'bg-purple-100 text-purple-800'
                          }`}
                        >
                          {statusLabels[application.currentStatus]}
                        </span>
                      </td>
                      <td className="px-6 py-4 text-sm text-gray-500">
                        {new Date(application.date).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex items-center gap-4">
                          <button
                            onClick={() => setShowDetailsModal(application.id)}
                            className="text-gray-600 hover:text-gray-900"
                            title="View Details"
                          >
                            <FileText size={18} />
                          </button>
                          <button
                            onClick={() => setSelectedApplication(application.id)}
                            className="text-blue-600 hover:text-blue-900"
                          >
                            Update Status
                          </button>
                          {!isInList(application.id) && application.phone && (
                            <button
                              onClick={() => handleAddToCallList(application)}
                              className="text-yellow-600 hover:text-yellow-700"
                              title="Add to Call List"
                            >
                              <Star size={18} />
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                    {expandedRows.includes(application.id) && (
                      <tr>
                        <td colSpan={7} className="px-6 py-4 bg-gray-50">
                          <div className="space-y-4">
                            <h4 className="font-medium text-gray-900">Status History</h4>
                            <div className="space-y-2">
                              {application.statusHistory.map((update) => (
                                <div
                                  key={update.id}
                                  className="flex items-start gap-4 text-sm"
                                >
                                  <div className="w-32 flex-shrink-0">
                                    <span
                                      className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                        update.status === 'new'
                                          ? 'bg-blue-100 text-blue-800'
                                          : update.status === 'accepted'
                                          ? 'bg-green-100 text-green-800'
                                          : update.status === 'rejected'
                                          ? 'bg-red-100 text-red-800'
                                          : 'bg-purple-100 text-purple-800'
                                      }`}
                                    >
                                      {statusLabels[update.status]}
                                    </span>
                                  </div>
                                  <div className="flex-grow">
                                    <p className="text-gray-900">{update.comment}</p>
                                    <p className="text-gray-500 text-xs">
                                      {new Date(update.date).toLocaleString()}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {selectedApplication && (
        <ApplicationStatusModal
          applicationId={selectedApplication}
          onClose={() => setSelectedApplication(null)}
        />
      )}

      {showNewModal && (
        <NewApplicationModal onClose={() => setShowNewModal(false)} />
      )}

      {showDetailsModal && (
        <ApplicantDetailsModal
          isOpen={true}
          onClose={() => setShowDetailsModal(null)}
          applicationId={showDetailsModal}
        />
      )}

      <BulkUploadModal
        isOpen={showBulkUploadModal}
        onClose={() => setShowBulkUploadModal(false)}
      />
    </div>
  );
}