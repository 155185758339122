import React, { useState } from 'react';
import { X, FileText, Clock, Plus, BriefcaseIcon } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import useApplicationsStore, { ApplicationStatus, MeetingReport, Reminder } from '../../store/applicationsStore';
import useJobsStore from '../../store/jobsStore';
import CVPreviewModal from '../CVPreviewModal';
import MeetingReportModal from './MeetingReportModal';
import MeetingReportsList from './MeetingReportsList';
import ReminderModal from './ReminderModal';
import RemindersList from './RemindersList';
import AssignPositionModal from './AssignPositionModal';

interface ApplicantDetailsModalProps {
  isOpen: boolean;
  onClose: () => void;
  applicationId: number;
}

const statusOptions: { value: ApplicationStatus; label: string }[] = [
  { value: 'new', label: 'New' },
  { value: 'accepted', label: 'Accepted' },
  { value: 'rejected', label: 'Rejected' },
  { value: 'first_meeting', label: 'First Meeting' },
  { value: 'second_meeting', label: 'Second Meeting' },
  { value: 'third_meeting', label: 'Third Meeting' },
  { value: 'fourth_meeting', label: 'Fourth Meeting' },
  { value: 'allianz_meeting', label: 'Allianz Meeting' },
];

export default function ApplicantDetailsModal({
  isOpen,
  onClose,
  applicationId,
}: ApplicantDetailsModalProps) {
  const { applications, updateStatus, addMeetingReport, updateMeetingReport, deleteMeetingReport, addReminder, updateReminder, deleteReminder, toggleReminderComplete } = useApplicationsStore();
  const { jobs } = useJobsStore();
  const [showCVPreview, setShowCVPreview] = useState(false);
  const [showMeetingModal, setShowMeetingModal] = useState(false);
  const [showReminderModal, setShowReminderModal] = useState(false);
  const [showAssignPositionModal, setShowAssignPositionModal] = useState(false);
  const [editingReport, setEditingReport] = useState<MeetingReport | null>(null);
  const [editingReminder, setEditingReminder] = useState<Reminder | null>(null);
  const [newStatus, setNewStatus] = useState<ApplicationStatus>('new');
  const [statusComment, setStatusComment] = useState('');
  
  const application = applications.find((app) => app.id === applicationId);
  const job = application ? jobs.find((j) => j.id === application.jobId) : null;

  if (!application) return null;

  const handleAddReport = (report: Omit<MeetingReport, 'id' | 'createdAt'>) => {
    addMeetingReport(applicationId, report);
  };

  const handleEditReport = (report: MeetingReport) => {
    setEditingReport(report);
    setShowMeetingModal(true);
  };

  const handleUpdateReport = (report: Omit<MeetingReport, 'id' | 'createdAt'>) => {
    if (editingReport) {
      updateMeetingReport(applicationId, editingReport.id, report);
    }
  };

  const handleAddReminder = (reminder: Omit<Reminder, 'id' | 'completed' | 'createdAt'>) => {
    addReminder(applicationId, reminder);
  };

  const handleEditReminder = (reminder: Reminder) => {
    setEditingReminder(reminder);
    setShowReminderModal(true);
  };

  const handleUpdateReminder = (reminder: Omit<Reminder, 'id' | 'completed' | 'createdAt'>) => {
    if (editingReminder) {
      updateReminder(applicationId, editingReminder.id, reminder);
    }
  };

  const handleStatusUpdate = (e: React.FormEvent) => {
    e.preventDefault();
    updateStatus(applicationId, newStatus, statusComment);
    setStatusComment('');
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="fixed inset-0 bg-black/50 z-50"
          />
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="fixed inset-0 z-50 flex items-center justify-center p-4"
            onClick={onClose}
          >
            <div 
              className="bg-white rounded-xl shadow-xl max-w-6xl w-full max-h-[90vh] overflow-y-auto"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="p-6">
                <div className="flex justify-between items-center mb-6">
                  <div>
                    <h2 className="text-2xl font-bold text-gray-900">
                      Applicant Details
                    </h2>
                    <p className="text-gray-600">
                      Applied for {job?.title} at {job?.companyName}
                    </p>
                  </div>
                  <div className="flex items-center gap-4">
                    <button
                      onClick={() => setShowAssignPositionModal(true)}
                      className="flex items-center gap-2 text-blue-600 hover:text-blue-700"
                    >
                      <BriefcaseIcon size={20} />
                      Assign to Position
                    </button>
                    <button
                      onClick={onClose}
                      className="text-gray-500 hover:text-gray-700"
                    >
                      <X size={24} />
                    </button>
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-8">
                  <div className="col-span-2 space-y-8">
                    <div>
                      <h3 className="text-lg font-semibold text-gray-900 mb-4">
                        Personal Information
                      </h3>
                      <div className="grid grid-cols-2 gap-4 bg-gray-50 p-4 rounded-lg">
                        <div>
                          <p className="text-sm text-gray-500">Full Name</p>
                          <p className="text-gray-900">
                            {application.firstName} {application.lastName}
                          </p>
                        </div>
                        <div>
                          <p className="text-sm text-gray-500">Email</p>
                          <p className="text-gray-900">{application.email}</p>
                        </div>
                        <div>
                          <p className="text-sm text-gray-500">Phone</p>
                          <p className="text-gray-900">{application.phone}</p>
                        </div>
                        <div>
                          <p className="text-sm text-gray-500">Application Date</p>
                          <p className="text-gray-900">
                            {new Date(application.date).toLocaleString()}
                          </p>
                        </div>
                      </div>
                    </div>

                    {application.cv && (
                      <div>
                        <h3 className="text-lg font-semibold text-gray-900 mb-4">
                          CV/Resume
                        </h3>
                        <button
                          onClick={() => setShowCVPreview(true)}
                          className="flex items-center gap-2 text-blue-600 hover:text-blue-700 bg-blue-50 px-4 py-2 rounded-lg"
                        >
                          <FileText size={20} />
                          {application.cv.name}
                        </button>
                      </div>
                    )}

                    {(application.notes || application.coverLetter) && (
                      <div>
                        <h3 className="text-lg font-semibold text-gray-900 mb-4">
                          Notes
                        </h3>
                        <div className="bg-gray-50 rounded-lg p-4">
                          <p className="text-gray-700 whitespace-pre-wrap">
                            {application.notes || application.coverLetter}
                          </p>
                        </div>
                      </div>
                    )}

                    <div>
                      <div className="flex justify-between items-center mb-4">
                        <h3 className="text-lg font-semibold text-gray-900">
                          Meeting Reports
                        </h3>
                        <button
                          onClick={() => {
                            setEditingReport(null);
                            setShowMeetingModal(true);
                          }}
                          className="flex items-center gap-2 text-blue-600 hover:text-blue-700"
                        >
                          <Plus size={20} />
                          Add Report
                        </button>
                      </div>
                      <MeetingReportsList
                        reports={application.meetingReports || []}
                        onEdit={handleEditReport}
                        onDelete={(reportId) => deleteMeetingReport(applicationId, reportId)}
                      />
                    </div>

                    <div>
                      <div className="flex justify-between items-center mb-4">
                        <h3 className="text-lg font-semibold text-gray-900">
                          Reminders
                        </h3>
                        <button
                          onClick={() => {
                            setEditingReminder(null);
                            setShowReminderModal(true);
                          }}
                          className="flex items-center gap-2 text-blue-600 hover:text-blue-700"
                        >
                          <Plus size={20} />
                          Add Reminder
                        </button>
                      </div>
                      <RemindersList
                        reminders={application.reminders || []}
                        onEdit={handleEditReminder}
                        onDelete={(reminderId) => deleteReminder(applicationId, reminderId)}
                        onToggleComplete={(reminderId) => toggleReminderComplete(applicationId, reminderId)}
                      />
                    </div>

                    <div>
                      <h3 className="text-lg font-semibold text-gray-900 mb-4">
                        Status History
                      </h3>
                      <div className="space-y-4">
                        {application.statusHistory.map((update) => (
                          <div
                            key={update.id}
                            className="bg-gray-50 rounded-lg p-4 flex items-start gap-4"
                          >
                            <Clock className="w-5 h-5 text-gray-400 mt-1" />
                            <div className="flex-1">
                              <div className="flex items-center gap-3 mb-2">
                                <span
                                  className={`px-2 py-1 text-xs font-semibold rounded-full ${
                                    update.status === 'new'
                                      ? 'bg-blue-100 text-blue-800'
                                      : update.status === 'accepted'
                                      ? 'bg-green-100 text-green-800'
                                      : update.status === 'rejected'
                                      ? 'bg-red-100 text-red-800'
                                      : 'bg-purple-100 text-purple-800'
                                  }`}
                                >
                                  {statusOptions.find(s => s.value === update.status)?.label}
                                </span>
                                <span className="text-sm text-gray-500">
                                  {new Date(update.date).toLocaleString()}
                                </span>
                              </div>
                              <p className="text-gray-700">{update.comment}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="col-span-1">
                    <div className="bg-gray-50 rounded-lg p-4 sticky top-6">
                      <h3 className="text-lg font-semibold text-gray-900 mb-4">
                        Update Status
                      </h3>
                      <form onSubmit={handleStatusUpdate} className="space-y-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            New Status
                          </label>
                          <select
                            value={newStatus}
                            onChange={(e) => setNewStatus(e.target.value as ApplicationStatus)}
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                          >
                            {statusOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Comment
                          </label>
                          <textarea
                            value={statusComment}
                            onChange={(e) => setStatusComment(e.target.value)}
                            required
                            rows={4}
                            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Add details about this status update..."
                          />
                        </div>
                        <button
                          type="submit"
                          className="w-full bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors"
                        >
                          Update Status
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>

          {application.cv && showCVPreview && (
            <CVPreviewModal
              isOpen={showCVPreview}
              onClose={() => setShowCVPreview(false)}
              cv={application.cv}
            />
          )}

          <MeetingReportModal
            isOpen={showMeetingModal}
            onClose={() => {
              setShowMeetingModal(false);
              setEditingReport(null);
            }}
            onSubmit={editingReport ? handleUpdateReport : handleAddReport}
            initialData={editingReport || undefined}
          />

          <ReminderModal
            isOpen={showReminderModal}
            onClose={() => {
              setShowReminderModal(false);
              setEditingReminder(null);
            }}
            onSubmit={editingReminder ? handleUpdateReminder : handleAddReminder}
            initialData={editingReminder || undefined}
          />

          <AssignPositionModal
            isOpen={showAssignPositionModal}
            onClose={() => setShowAssignPositionModal(false)}
            applicant={{
              id: applicationId,
              firstName: application.firstName,
              lastName: application.lastName,
              email: application.email,
              phone: application.phone,
              cv: application.cv,
              coverLetter: application.coverLetter
            }}
          />
        </>
      )}
    </AnimatePresence>
  );
}