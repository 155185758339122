import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import useJobsStore from '../../store/jobsStore';
import useApplicationsStore from '../../store/applicationsStore';

interface AssignPositionModalProps {
  isOpen: boolean;
  onClose: () => void;
  applicant: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    cv?: {
      name: string;
      data: string;
      type: string;
    };
    coverLetter: string;
  };
}

export default function AssignPositionModal({
  isOpen,
  onClose,
  applicant,
}: AssignPositionModalProps) {
  const { jobs } = useJobsStore();
  const { addApplication } = useApplicationsStore();
  const [selectedJobId, setSelectedJobId] = useState('');
  const [notes, setNotes] = useState('');

  const activeJobs = jobs.filter(job => job.isActive);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (selectedJobId) {
      addApplication({
        firstName: applicant.firstName,
        lastName: applicant.lastName,
        email: applicant.email,
        phone: applicant.phone,
        position: jobs.find(j => j.id === selectedJobId)?.title || '',
        jobId: selectedJobId,
        coverLetter: applicant.coverLetter,
        cv: applicant.cv,
        notes: notes,
      });
      onClose();
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="fixed inset-0 bg-black/50 z-50"
          />
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="fixed inset-0 z-50 flex items-center justify-center p-4"
            onClick={onClose}
          >
            <div 
              className="bg-white rounded-xl shadow-xl max-w-lg w-full"
              onClick={e => e.stopPropagation()}
            >
              <div className="p-6">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-2xl font-bold text-gray-900">
                    Assign to Position
                  </h2>
                  <button
                    onClick={onClose}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <X size={24} />
                  </button>
                </div>

                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Select Position
                    </label>
                    <select
                      value={selectedJobId}
                      onChange={(e) => setSelectedJobId(e.target.value)}
                      required
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="">Select a position...</option>
                      {activeJobs.map((job) => (
                        <option key={job.id} value={job.id}>
                          {job.title} at {job.companyName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Notes (Optional)
                    </label>
                    <textarea
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                      rows={4}
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                      placeholder="Add any notes about this position assignment..."
                    />
                  </div>

                  <button
                    type="submit"
                    disabled={!selectedJobId}
                    className="w-full bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    Assign Position
                  </button>
                </form>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}