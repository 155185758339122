import React, { useState } from 'react';
import { Users, BriefcaseIcon, MessageSquare, TrendingUp, Bell } from 'lucide-react';
import useStatsStore from '../../store/statsStore';
import useMessagesStore from '../../store/messagesStore';
import useApplicationsStore from '../../store/applicationsStore';
import useJobsStore from '../../store/jobsStore';
import ApplicantDetailsModal from './ApplicantDetailsModal';

export default function AdminDashboard() {
  const stats = useStatsStore();
  const { messages } = useMessagesStore();
  const { applications } = useApplicationsStore();
  const { jobs } = useJobsStore();
  const [selectedApplication, setSelectedApplication] = useState<number | null>(null);

  const unreadMessages = messages.filter(msg => !msg.read).length;
  const activeJobs = jobs.filter(job => job.isActive).length;

  // Get today's and upcoming reminders
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  
  const reminders = applications.flatMap(app => 
    (app.reminders || []).map(reminder => ({
      ...reminder,
      applicationId: app.id,
      applicantName: `${app.firstName} ${app.lastName}`
    }))
  ).filter(reminder => !reminder.completed)
    .sort((a, b) => new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime());

  const todayReminders = reminders.filter(reminder => {
    const dueDate = new Date(reminder.dueDate);
    dueDate.setHours(0, 0, 0, 0);
    return dueDate.getTime() <= today.getTime();
  });

  const upcomingReminders = reminders.filter(reminder => {
    const dueDate = new Date(reminder.dueDate);
    dueDate.setHours(0, 0, 0, 0);
    return dueDate.getTime() > today.getTime();
  }).slice(0, 5); // Show only next 5 upcoming reminders

  const statsData = [
    {
      name: 'Total Applications',
      value: applications.length.toString(),
      icon: BriefcaseIcon,
      change: '+12%',
      changeType: 'increase',
    },
    {
      name: 'New Messages',
      value: unreadMessages.toString(),
      icon: MessageSquare,
      change: '+4%',
      changeType: 'increase',
    },
    {
      name: 'Active Jobs',
      value: activeJobs.toString(),
      icon: Users,
      change: '0%',
      changeType: 'neutral',
    },
    {
      name: 'Conversion Rate',
      value: '24.57%',
      icon: TrendingUp,
      change: '+2.5%',
      changeType: 'increase',
    },
  ];

  return (
    <div>
      <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
      
      <div className="mt-4 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        {statsData.map((item) => (
          <div
            key={item.name}
            className="bg-white overflow-hidden shadow rounded-lg"
          >
            <div className="p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <item.icon
                    className="h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-5 w-0 flex-1">
                  <dl>
                    <dt className="text-sm font-medium text-gray-500 truncate">
                      {item.name}
                    </dt>
                    <dd className="flex items-baseline">
                      <div className="text-2xl font-semibold text-gray-900">
                        {item.value}
                      </div>
                      <div
                        className={`ml-2 flex items-baseline text-sm font-semibold ${
                          item.changeType === 'increase'
                            ? 'text-green-600'
                            : item.changeType === 'decrease'
                            ? 'text-red-600'
                            : 'text-gray-500'
                        }`}
                      >
                        {item.change}
                      </div>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="bg-white shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
              Latest Applications
            </h3>
            <div className="flow-root">
              <ul className="-my-5 divide-y divide-gray-200">
                {applications.slice(0, 5).map((application) => {
                  const job = jobs.find(j => j.id === application.jobId);
                  return (
                    <li key={application.id} className="py-4">
                      <div className="flex items-center space-x-4">
                        <div className="flex-shrink-0">
                          <Users className="h-6 w-6 text-gray-400" />
                        </div>
                        <div className="flex-1 min-w-0">
                          <p className="text-sm font-medium text-gray-900 truncate">
                            {application.firstName} {application.lastName}
                          </p>
                          <p className="text-sm text-gray-500">
                            Applied for {job?.title} at {job?.companyName}
                          </p>
                          <p className="text-xs text-gray-400">
                            {new Date(application.date).toLocaleString()}
                          </p>
                        </div>
                        <div>
                          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                            application.currentStatus === 'new' ? 'bg-blue-100 text-blue-800' : 
                            application.currentStatus === 'accepted' ? 'bg-green-100 text-green-800' :
                            'bg-gray-100 text-gray-800'
                          }`}>
                            {application.currentStatus.charAt(0).toUpperCase() + application.currentStatus.slice(1)}
                          </span>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>

        <div className="bg-white shadow rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Reminders
              </h3>
              <Bell className="h-5 w-5 text-gray-400" />
            </div>
            
            {todayReminders.length > 0 && (
              <div className="mb-6">
                <h4 className="text-sm font-medium text-red-600 mb-3">Overdue / Today</h4>
                <div className="space-y-3">
                  {todayReminders.map((reminder) => (
                    <div
                      key={reminder.id}
                      className="flex items-center justify-between p-3 bg-red-50 rounded-lg border border-red-100 cursor-pointer hover:bg-red-100 transition-colors"
                      onClick={() => setSelectedApplication(reminder.applicationId)}
                    >
                      <div>
                        <p className="text-sm font-medium text-gray-900">
                          {reminder.note}
                        </p>
                        <p className="text-xs text-gray-500">
                          {reminder.applicantName}
                        </p>
                      </div>
                      <span className="text-xs text-red-600 font-medium">
                        Due: {new Date(reminder.dueDate).toLocaleDateString()}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {upcomingReminders.length > 0 && (
              <div>
                <h4 className="text-sm font-medium text-blue-600 mb-3">Upcoming</h4>
                <div className="space-y-3">
                  {upcomingReminders.map((reminder) => (
                    <div
                      key={reminder.id}
                      className="flex items-center justify-between p-3 bg-blue-50 rounded-lg border border-blue-100 cursor-pointer hover:bg-blue-100 transition-colors"
                      onClick={() => setSelectedApplication(reminder.applicationId)}
                    >
                      <div>
                        <p className="text-sm font-medium text-gray-900">
                          {reminder.note}
                        </p>
                        <p className="text-xs text-gray-500">
                          {reminder.applicantName}
                        </p>
                      </div>
                      <span className="text-xs text-blue-600 font-medium">
                        Due: {new Date(reminder.dueDate).toLocaleDateString()}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {todayReminders.length === 0 && upcomingReminders.length === 0 && (
              <div className="text-center text-gray-500 py-8">
                No active reminders
              </div>
            )}
          </div>
        </div>
      </div>

      {selectedApplication && (
        <ApplicantDetailsModal
          isOpen={true}
          onClose={() => setSelectedApplication(null)}
          applicationId={selectedApplication}
        />
      )}
    </div>
  );
}