import React, { useState, useRef } from 'react';
import { X, Upload, Loader2 } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import useApplicationsStore from '../../store/applicationsStore';
import useJobsStore from '../../store/jobsStore';

interface BulkUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface FileWithPreview extends File {
  preview?: string;
}

export default function BulkUploadModal({ isOpen, onClose }: BulkUploadModalProps) {
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedJobId, setSelectedJobId] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const addApplication = useApplicationsStore((state) => state.addApplication);
  const { jobs } = useJobsStore();

  const activeJobs = jobs.filter(job => job.isActive);

  const validateFile = (file: File) => {
    const validTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];
    const maxSize = 10 * 1024 * 1024; // 10MB

    if (!validTypes.includes(file.type)) {
      throw new Error(`Invalid file type: ${file.name}. Please upload PDF, DOC, or DOCX files only.`);
    }

    if (file.size > maxSize) {
      throw new Error(`File too large: ${file.name}. Maximum size is 10MB.`);
    }

    return true;
  };

  const processFiles = async (newFiles: File[]) => {
    setError(null);
    setIsUploading(true);

    try {
      const validFiles = [];
      for (const file of newFiles) {
        try {
          validateFile(file);
          validFiles.push(file);
        } catch (err) {
          if (err instanceof Error) {
            setError(err.message);
          }
          return;
        }
      }

      const filteredFiles = validFiles.filter(file => 
        !files.some(existingFile => existingFile.name === file.name)
      );

      setFiles(prev => [...prev, ...filteredFiles]);
    } catch (err) {
      setError('Error processing files. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      processFiles(Array.from(e.target.files));
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    const droppedFiles = Array.from(e.dataTransfer.files);
    await processFiles(droppedFiles);
  };

  const removeFile = (fileName: string) => {
    setFiles(files.filter(file => file.name !== fileName));
  };

  const readFileAsDataURL = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          resolve(reader.result);
        } else {
          reject(new Error('Failed to read file data'));
        }
      };
      reader.onerror = () => reject(new Error('Failed to read file'));
      reader.readAsDataURL(file);
    });
  };

  const extractNameFromFileName = (fileName: string): { firstName: string; lastName: string } => {
    // Remove file extension and split by common separators
    const namePart = fileName.replace(/\.[^/.]+$/, '').split(/[-_\s]+/);
    
    if (namePart.length >= 2) {
      return {
        firstName: namePart[0],
        lastName: namePart[1]
      };
    }
    
    return {
      firstName: namePart[0] || 'Unknown',
      lastName: 'Unknown'
    };
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!selectedJobId) {
      setError('Please select a job position');
      return;
    }

    setIsUploading(true);
    setError(null);

    try {
      const job = jobs.find(j => j.id === selectedJobId);
      if (!job) throw new Error('Selected job not found');

      for (const file of files) {
        const fileData = await readFileAsDataURL(file);
        const { firstName, lastName } = extractNameFromFileName(file.name);
        
        addApplication({
          firstName,
          lastName,
          email: '',
          phone: '',
          position: job.title,
          jobId: job.id,
          notes: `Bulk uploaded from file: ${file.name}`,
          cv: {
            name: file.name,
            data: fileData,
            type: file.type
          }
        });
      }

      onClose();
    } catch (err) {
      setError('Error uploading files. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="fixed inset-0 bg-black/50 z-50"
          />
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            className="fixed inset-0 z-50 flex items-center justify-center p-4"
            onClick={onClose}
          >
            <div
              className="bg-white rounded-xl shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto"
              onClick={e => e.stopPropagation()}
            >
              <div className="p-6">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-2xl font-bold text-gray-900">
                    Bulk Upload Applicants
                  </h2>
                  <button
                    onClick={onClose}
                    className="text-gray-500 hover:text-gray-700"
                  >
                    <X size={24} />
                  </button>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="mb-6">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Select Position
                    </label>
                    <select
                      value={selectedJobId}
                      onChange={(e) => setSelectedJobId(e.target.value)}
                      required
                      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="">Select a position...</option>
                      {activeJobs.map((job) => (
                        <option key={job.id} value={job.id}>
                          {job.title} at {job.companyName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                    onClick={() => fileInputRef.current?.click()}
                    className="border-2 border-dashed border-gray-300 rounded-lg p-8 text-center hover:border-blue-500 transition-colors cursor-pointer"
                  >
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileSelect}
                      multiple
                      accept=".pdf,.doc,.docx"
                      className="hidden"
                    />
                    <div className="flex flex-col items-center">
                      <Upload className="h-12 w-12 text-gray-400 mb-4" />
                      <p className="text-gray-600 mb-2">
                        Drag and drop CVs here, or click to select files
                      </p>
                      <p className="text-sm text-gray-500">
                        Name format: FirstName_LastName.pdf
                      </p>
                      <p className="text-sm text-gray-500">
                        Supports PDF, DOC, DOCX up to 10MB each
                      </p>
                    </div>
                  </div>

                  {error && (
                    <div className="mt-4 text-red-600 text-sm text-center">
                      {error}
                    </div>
                  )}

                  {files.length > 0 && (
                    <div className="mt-6">
                      <h3 className="text-sm font-medium text-gray-700 mb-3">
                        Selected Files ({files.length})
                      </h3>
                      <div className="space-y-2">
                        {files.map((file) => (
                          <div
                            key={file.name}
                            className="flex items-center justify-between bg-gray-50 p-3 rounded-lg"
                          >
                            <span className="text-sm text-gray-600">
                              {file.name}
                            </span>
                            <button
                              type="button"
                              onClick={() => removeFile(file.name)}
                              className="text-red-600 hover:text-red-700"
                            >
                              <X size={18} />
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  <div className="mt-6">
                    <button
                      type="submit"
                      disabled={isUploading || files.length === 0 || !selectedJobId}
                      className="w-full bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
                    >
                      {isUploading ? (
                        <>
                          <Loader2 className="h-5 w-5 animate-spin" />
                          Uploading...
                        </>
                      ) : (
                        'Upload Files'
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}