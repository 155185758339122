import React from 'react';
import { ArrowRight } from 'lucide-react';
import { motion } from 'framer-motion';
import useOfferwallStore from '../../../store/offerwallStore';
import TimelineProgress from '../TimelineProgress';

export default function WelcomeStep() {
  const { nextStep } = useOfferwallStore();

  return (
    <div>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center mb-12"
      >
        <h2 className="text-4xl font-bold text-gray-900 mb-4">
          İşe Alım Sürecinizi Dönüştürün
        </h2>
        <p className="text-xl text-gray-600 max-w-2xl mx-auto">
          iTalentHunt ile işe alım süreçlerinizi optimize edin, zamandan tasarruf edin 
          ve en uygun yeteneklerle tanışın. Başlamak için sadece birkaç dakikanızı ayırın.
        </p>
      </motion.div>

      <TimelineProgress />

      <div className="text-center space-y-8">
        <div className="bg-blue-50 rounded-xl p-6 max-w-2xl mx-auto">
          <h3 className="text-lg font-semibold text-blue-900 mb-2">
            Neden iTalentHunt?
          </h3>
          <ul className="text-blue-800 space-y-2">
            <li>✓ Ortalama işe alım süresini 45 günden 15 güne indirin</li>
            <li>✓ İK ekibinizin verimliliğini %40 artırın</li>
            <li>✓ Yanlış işe alımlardan kaynaklanan maliyetleri azaltın</li>
            <li>✓ Aday deneyimini iyileştirerek marka değerinizi artırın</li>
          </ul>
        </div>

        <button
          onClick={nextStep}
          className="inline-flex items-center px-8 py-4 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors text-lg font-medium"
        >
          Hemen Başlayın
          <ArrowRight className="ml-2 h-6 w-6" />
        </button>

        <p className="text-sm text-gray-500">
          5 dakikadan kısa sürede tamamlayın, uzmanlarımız sizinle iletişime geçsin
        </p>
      </div>
    </div>
  );
}