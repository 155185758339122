import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { supabase } from '../lib/supabase';
import type { UserRole } from '../types/supabase';

interface Profile {
  id: string;
  email: string;
  fullName: string;
  role: UserRole;
  avatarUrl?: string;
}

interface AuthState {
  session: any | null;
  profile: Profile | null;
  isLoading: boolean;
  error: string | null;
  initialize: () => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  createUser: (email: string, password: string, fullName: string, role: UserRole) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  updatePassword: (newPassword: string) => Promise<void>;
}

const useAuthStore = create<AuthState>()(
  persist(
    (set, get) => ({
      session: null,
      profile: null,
      isLoading: true,
      error: null,

      initialize: async () => {
        try {
          // Get initial session
          const { data: { session } } = await supabase.auth.getSession();
          
          if (session?.user) {
            // Fetch user profile
            const { data: profile } = await supabase
              .from('profiles')
              .select('*')
              .eq('id', session.user.id)
              .single();

            set({
              session,
              profile: profile ? {
                id: profile.id,
                email: profile.email,
                fullName: profile.full_name,
                role: profile.role,
                avatarUrl: profile.avatar_url
              } : null,
              isLoading: false
            });
          } else {
            set({ session: null, profile: null, isLoading: false });
          }
        } catch (error) {
          console.error('Error initializing auth:', error);
          set({ error: 'Failed to initialize authentication', isLoading: false });
        }
      },

      login: async (email: string, password: string) => {
        try {
          set({ isLoading: true, error: null });
          
          const { data, error } = await supabase.auth.signInWithPassword({
            email,
            password,
          });

          if (error) throw error;

          if (data.user) {
            const { data: profile } = await supabase
              .from('profiles')
              .select('*')
              .eq('id', data.user.id)
              .single();

            set({
              session: data.session,
              profile: profile ? {
                id: profile.id,
                email: profile.email,
                fullName: profile.full_name,
                role: profile.role,
                avatarUrl: profile.avatar_url
              } : null,
              isLoading: false
            });
          }
        } catch (error) {
          console.error('Error logging in:', error);
          set({ error: 'Invalid email or password', isLoading: false });
        }
      },

      logout: async () => {
        try {
          set({ isLoading: true, error: null });
          await supabase.auth.signOut();
          set({ session: null, profile: null, isLoading: false });
        } catch (error) {
          console.error('Error logging out:', error);
          set({ error: 'Failed to log out', isLoading: false });
        }
      },

      createUser: async (email: string, password: string, fullName: string, role: UserRole) => {
        const { profile } = get();
        
        // Only founders can create new users
        if (profile?.role !== 'founder') {
          set({ error: 'Unauthorized to create new users' });
          return;
        }

        try {
          set({ isLoading: true, error: null });

          // Create auth user
          const { data: { user }, error: signUpError } = await supabase.auth.signUp({
            email,
            password,
          });

          if (signUpError) throw signUpError;
          if (!user) throw new Error('Failed to create user');

          // Create profile
          const { error: profileError } = await supabase
            .from('profiles')
            .insert({
              id: user.id,
              email,
              full_name: fullName,
              role,
            });

          if (profileError) throw profileError;

          set({ isLoading: false });
        } catch (error) {
          console.error('Error creating user:', error);
          set({ error: 'Failed to create user', isLoading: false });
        }
      },

      resetPassword: async (email: string) => {
        try {
          set({ isLoading: true, error: null });
          
          const { error } = await supabase.auth.resetPasswordForEmail(email, {
            redirectTo: `${window.location.origin}/update-password`,
          });
          
          if (error) throw error;
          
          set({ isLoading: false });
        } catch (error) {
          console.error('Error resetting password:', error);
          set({ 
            error: 'Failed to send reset password email. Please try again.',
            isLoading: false 
          });
        }
      },

      updatePassword: async (newPassword: string) => {
        try {
          set({ isLoading: true, error: null });
          
          const { error } = await supabase.auth.updateUser({
            password: newPassword
          });
          
          if (error) throw error;
          
          set({ isLoading: false });
        } catch (error) {
          console.error('Error updating password:', error);
          set({ 
            error: 'Failed to update password. Please try again.',
            isLoading: false 
          });
        }
      },
    }),
    {
      name: 'auth-storage',
      partialize: (state) => ({
        session: state.session,
        profile: state.profile,
      }),
    }
  )
);

export default useAuthStore;