import React, { useRef } from 'react';
import { Upload } from 'lucide-react';
import { openCV } from '../utils/fileUtils';

interface FileUploadProps {
  cv: { name: string; data: string; type: string } | null;
  isUploading: boolean;
  uploadError: string | null;
  onFileSelect: (file: { name: string; data: string; type: string }) => void;
  onError: (error: string) => void;
}

export default function FileUpload({ cv, isUploading, uploadError, onFileSelect, onError }: FileUploadProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileUpload = async () => {
    try {
      const file = await openCV(fileInputRef);
      onFileSelect(file);
    } catch (error) {
      if (error instanceof Error) {
        onError(error.message);
      } else {
        onError('Failed to upload file. Please try again.');
      }
    }
  };

  return (
    <div
      onClick={() => fileInputRef.current?.click()}
      className={`mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-dashed rounded-lg cursor-pointer hover:border-blue-400 transition-colors ${
        uploadError ? 'border-red-300' : 'border-gray-300'
      }`}
    >
      <div className="space-y-1 text-center">
        <Upload className={`mx-auto h-12 w-12 ${
          uploadError ? 'text-red-400' : 'text-gray-400'
        }`} />
        <div className="flex text-sm text-gray-600">
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileUpload}
            accept=".pdf,.doc,.docx"
            className="hidden"
          />
          <span className="relative font-medium text-blue-600 hover:text-blue-500">
            {isUploading ? 'Uploading...' : cv ? 'Change file' : 'Upload a file'}
          </span>
        </div>
        {cv && (
          <p className="text-sm text-gray-500">{cv.name}</p>
        )}
        {!cv && !uploadError && (
          <p className="text-xs text-gray-500">
            PDF, DOC, DOCX up to 10MB
          </p>
        )}
        {uploadError && (
          <p className="text-xs text-red-500">{uploadError}</p>
        )}
      </div>
    </div>
  );
}