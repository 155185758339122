import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { 
  Users, Target, BarChart, CheckCircle, 
  Building, Clock, Award, Phone, Mail, MapPin
} from 'lucide-react';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../components/LanguageSelector';
import RotatingTestimonials from '../components/RotatingTestimonials';
import { testimonials } from '../data/testimonials';
import ContactModal from '../components/ContactModal';

export default function OnePager() {
  const { lang = 'tr' } = useParams();
  const { t, i18n } = useTranslation();
  const [showContactModal, setShowContactModal] = useState(false);
  
  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const services = [
    {
      icon: Users,
      title: t('services.items.recruitment.title'),
      items: [
        t('onepager.services.recruitment.items.1'),
        t('onepager.services.recruitment.items.2'),
        t('onepager.services.recruitment.items.3'),
        t('onepager.services.recruitment.items.4')
      ]
    },
    {
      icon: Target,
      title: t('services.items.consulting.title'),
      items: [
        t('onepager.services.consulting.items.1'),
        t('onepager.services.consulting.items.2'),
        t('onepager.services.consulting.items.3')
      ]
    },
    {
      icon: Building,
      title: t('services.items.development.title'),
      items: [
        t('onepager.services.development.items.1'),
        t('onepager.services.development.items.2'),
        t('onepager.services.development.items.3')
      ]
    }
  ];

  const stats = [
    { label: t('onepager.stats.experience'), value: '10+' },
    { label: t('onepager.stats.successRate'), value: '%95' },
    { label: t('onepager.stats.clients'), value: '10+' },
    { label: t('onepager.stats.talents'), value: '100+' }
  ];

  const steps = [
    {
      title: t('onepager.process.steps.1.title'),
      description: t('onepager.process.steps.1.description')
    },
    {
      title: t('onepager.process.steps.2.title'),
      description: t('onepager.process.steps.2.description')
    },
    {
      title: t('onepager.process.steps.3.title'),
      description: t('onepager.process.steps.3.description')
    },
    {
      title: t('onepager.process.steps.4.title'),
      description: t('onepager.process.steps.4.description')
    }
  ];

  const currentTestimonials = testimonials[lang as keyof typeof testimonials] || testimonials.tr;

  return (
    <div className="bg-white">
      <div className="fixed top-4 right-4 z-50">
        <LanguageSelector />
      </div>

      <section className="relative min-h-screen flex items-center bg-gradient-to-br from-blue-50 to-white">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute -top-1/2 -right-1/2 w-[1000px] h-[1000px] rounded-full bg-blue-100/50"></div>
          <div className="absolute -bottom-1/2 -left-1/2 w-[1000px] h-[1000px] rounded-full bg-purple-100/30"></div>
        </div>
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20">
          <div className="text-center max-w-3xl mx-auto">
            <motion.h1 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="text-5xl md:text-6xl font-bold text-gray-900 mb-6"
            >
              {t('onepager.hero.title')}
            </motion.h1>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-xl text-gray-600 mb-8"
            >
              {t('onepager.hero.subtitle')}
            </motion.p>
            <motion.button
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              onClick={() => setShowContactModal(true)}
              className="bg-blue-600 text-white px-8 py-4 rounded-full text-lg font-medium hover:bg-blue-700 transition-colors"
            >
              {t('onepager.hero.cta')}
            </motion.button>
          </div>
        </div>
      </section>

      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-gray-900 mb-4">{t('services.title')}</h2>
            <p className="text-xl text-gray-600">{t('services.subtitle')}</p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <motion.div
                key={service.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                viewport={{ once: true }}
                className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow"
              >
                <service.icon className="w-12 h-12 text-blue-600 mb-6" />
                <h3 className="text-xl font-semibold text-gray-900 mb-4">{service.title}</h3>
                <ul className="space-y-2">
                  {service.items.map((item, i) => (
                    <li key={i} className="flex items-start gap-2 text-gray-600">
                      <CheckCircle className="w-5 h-5 text-blue-600 flex-shrink-0 mt-0.5" />
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-20 bg-blue-600">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {stats.map((stat, index) => (
              <motion.div
                key={stat.label}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                viewport={{ once: true }}
                className="text-center"
              >
                <div className="text-4xl font-bold text-white mb-2">{stat.value}</div>
                <div className="text-blue-100">{stat.label}</div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-gray-900 mb-4">{t('onepager.process.title')}</h2>
            <p className="text-xl text-gray-600">{t('onepager.process.subtitle')}</p>
          </div>

          <div className="grid md:grid-cols-4 gap-8">
            {steps.map((step, index) => (
              <motion.div
                key={step.title}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.2 }}
                viewport={{ once: true }}
                className="text-center"
              >
                <div className="w-12 h-12 bg-blue-600 text-white rounded-full flex items-center justify-center text-xl font-bold mx-auto mb-4">
                  {index + 1}
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-gray-900 mb-4">{t('onepager.testimonials.title')}</h2>
            <p className="text-xl text-gray-600">{t('onepager.testimonials.subtitle')}</p>
          </div>

          <div className="max-w-3xl mx-auto">
            <RotatingTestimonials testimonials={currentTestimonials} />
          </div>
        </div>
      </section>

      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div>
              <h2 className="text-4xl font-bold text-gray-900 mb-6">{t('contact.title')}</h2>
              <p className="text-xl text-gray-600 mb-8">{t('contact.subtitle')}</p>
              <div className="space-y-4">
                <div className="flex items-center gap-4">
                  <Mail className="w-6 h-6 text-blue-600" />
                  <div>
                    <div className="font-medium">{t('contact.info.email')}</div>
                    <div className="text-gray-600">info@italenthunt.com</div>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <Phone className="w-6 h-6 text-blue-600" />
                  <div>
                    <div className="font-medium">{t('contact.info.phone')}</div>
                    <div className="text-gray-600">+90 (541) 660 88 38</div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <img
                src="https://images.unsplash.com/photo-1497366216548-37526070297c?auto=format&fit=crop&q=80"
                alt="Office"
                className="rounded-xl shadow-lg"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="py-20 bg-blue-600">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-white mb-4">
            {t('onepager.cta.title')}
          </h2>
          <button
            onClick={() => setShowContactModal(true)}
            className="inline-block bg-white text-blue-600 px-8 py-4 rounded-full font-semibold hover:bg-blue-50 transition-colors"
          >
            {t('onepager.cta.button')}
          </button>
        </div>
      </section>

      <ContactModal 
        isOpen={showContactModal} 
        onClose={() => setShowContactModal(false)} 
      />
    </div>
  );
}