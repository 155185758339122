import { supabase } from '../lib/supabase';
import { sendEmail } from '../utils/emailService';
import { logError } from '../utils/errorHandling';
import type { OfferwallSubmission } from '../types/offerwall';

export async function submitOfferwallApplication(data: OfferwallSubmission) {
  try {
    // First, insert the submission
    const { error: submissionError } = await supabase
      .from('offerwall_submissions')
      .insert({
        needs: data.needs,
        position_count: data.positions.count,
        position_types: data.positions.types,
        employment_types: data.positions.employmentType,
        solution_type: data.solution,
        company_name: data.contact.companyName,
        contact_name: data.contact.fullName,
        contact_email: data.contact.email,
        contact_phone: data.contact.phone,
        contact_message: data.contact.message,
        status: 'new'
      });

    if (submissionError) {
      logError('Database submission', submissionError);
      throw new Error('Başvurunuz kaydedilirken bir hata oluştu. Lütfen tekrar deneyin.');
    }

    // Send notification emails
    await Promise.all([
      sendAdminNotification(data),
      sendCompanyConfirmation(data)
    ]);

    return { success: true };
  } catch (error) {
    logError('Offerwall submission', error);
    throw error;
  }
}

async function sendAdminNotification(data: OfferwallSubmission) {
  const message = `
    Yeni bir firma başvurusu alındı:
    
    Firma: ${data.contact.companyName}
    İletişim: ${data.contact.fullName}
    E-posta: ${data.contact.email}
    Telefon: ${data.contact.phone}
    
    İhtiyaçlar: ${data.needs.join(', ')}
    Pozisyon Sayısı: ${data.positions.count}
    Pozisyonlar: ${data.positions.types.join(', ')}
    Çalışma Şekli: ${data.positions.employmentType.join(', ')}
    Çözüm Tipi: ${data.solution}
    
    ${data.contact.message ? `Mesaj: ${data.contact.message}` : ''}
  `.trim();

  return sendEmail({
    name: 'Admin',
    email: 'ozgylbasakkalkan@gmail.com',
    message,
    subject: 'Yeni Firma Başvurusu',
  }, 'template_xp50vf4');
}

async function sendCompanyConfirmation(data: OfferwallSubmission) {
  const message = `
    Sayın ${data.contact.fullName},

    iTalentHunt'a gösterdiğiniz ilgi için teşekkür ederiz. Başvurunuz başarıyla alınmıştır.
    
    En kısa sürede sizinle iletişime geçeceğiz.
    
    İyi çalışmalar,
    iTalentHunt Ekibi
  `.trim();

  return sendEmail({
    name: data.contact.fullName,
    email: data.contact.email,
    message,
    subject: 'Başvurunuz Alındı - iTalentHunt',
  }, 'template_xp50vf4');
}