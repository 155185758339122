interface Testimonial {
  id: number;
  text: string;
  author: string;
  position: string;
  company: string;
}

export const testimonials: Record<string, Testimonial[]> = {
  tr: [
    {
      id: 1,
      text: "İşe alım süreçlerimizde gösterdikleri profesyonellik ve hız için iTalentHunt'a teşekkür ederiz. Ekip, ihtiyaçlarımızı tam olarak anladı ve beklentilerimizin ötesinde sonuçlar üretti.",
      author: "Mehmet Yılmaz",
      position: "İK Direktörü"
    },
    {
      id: 2,
      text: "Yetenek arayışımızda iTalentHunt'ın stratejik yaklaşımı ve sektör bilgisi büyük fark yarattı. Kısa sürede doğru adayları bulmamıza yardımcı oldular.",
      author: "Ayşe Kaya",
      position: "Genel Müdür"
    },
    {
      id: 3,
      text: "iTalentHunt ile çalışmak gerçekten çok verimli. Aday havuzlarının genişliği ve değerlendirme süreçlerindeki titizlikleri takdire şayan.",
      author: "Can Demir",
      position: "İnsan Kaynakları Müdürü"
    }
  ],
  en: [
    {
      id: 1,
      text: "We thank iTalentHunt for their professionalism and speed in our recruitment processes. The team fully understood our needs and produced results beyond our expectations.",
      author: "John Smith",
      position: "HR Director"
    },
    {
      id: 2,
      text: "iTalentHunt's strategic approach and industry knowledge made a big difference in our talent search. They helped us find the right candidates in a short time.",
      author: "Sarah Johnson",
      position: "General Manager"
    },
    {
      id: 3,
      text: "Working with iTalentHunt has been truly efficient. The breadth of their candidate pools and their thoroughness in evaluation processes is admirable.",
      author: "Michael Brown",
      position: "HR Manager"
    }
  ],
  fi: [
    {
      id: 1,
      text: "Kiitämme iTalentHuntia ammattimaisuudesta ja nopeudesta rekrytointiprosesseissamme. Tiimi ymmärsi täysin tarpeemme ja tuotti odotuksiamme parempia tuloksia.",
      author: "Mikko Virtanen",
      position: "Henkilöstöjohtaja"
    },
    {
      id: 2,
      text: "iTalentHuntin strateginen lähestymistapa ja toimialatuntemus tekivät suuren eron kykyjen etsinnässämme. He auttoivat meitä löytämään oikeat kandidaatit lyhyessä ajassa.",
      author: "Laura Korhonen",
      position: "Toimitusjohtaja"
    },
    {
      id: 3,
      text: "Yhteistyö iTalentHuntin kanssa on ollut todella tehokasta. Heidän kandidaattipooliensa laajuus ja perusteellisuus arviointiprosesseissa on ihailtavaa.",
      author: "Juhani Mäkelä",
      position: "HR-päällikkö"
    }
  ]
};