import React from 'react';
import { Check } from 'lucide-react';
import useOfferwallStore, { SolutionType } from '../../../store/offerwallStore';

const solutions = [
  {
    id: 'full_process' as SolutionType,
    label: 'Aday seçim sürecini baştan sona yönetmemizi istiyorum',
  },
  {
    id: 'candidate_pool' as SolutionType,
    label: 'Aday havuzu oluşturmak için destek almak istiyorum',
  },
  {
    id: 'cv_screening' as SolutionType,
    label: 'Sadece CV taraması ve değerlendirme hizmeti istiyorum',
  },
  {
    id: 'custom' as SolutionType,
    label: 'Başka bir ihtiyacım var',
  },
];

export default function SolutionStep() {
  const { formData, updateSolution, nextStep, prevStep } = useOfferwallStore();
  const [selectedSolution, setSelectedSolution] = React.useState<SolutionType>(formData.solution);

  const handleNext = () => {
    updateSolution(selectedSolution);
    nextStep();
  };

  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-900 mb-4">
        Size Nasıl Yardımcı Olalım?
      </h2>
      <p className="text-gray-600 mb-6">
        İhtiyacınıza en uygun çözümü seçin.
      </p>

      <div className="space-y-3 mb-8">
        {solutions.map((solution) => (
          <button
            key={solution.id}
            onClick={() => setSelectedSolution(solution.id)}
            className={`w-full flex items-center p-4 rounded-lg border-2 transition-colors ${
              selectedSolution === solution.id
                ? 'border-blue-600 bg-blue-50'
                : 'border-gray-200 hover:border-blue-200'
            }`}
          >
            <div
              className={`w-6 h-6 rounded-full border-2 mr-4 flex items-center justify-center ${
                selectedSolution === solution.id
                  ? 'border-blue-600 bg-blue-600 text-white'
                  : 'border-gray-300'
              }`}
            >
              {selectedSolution === solution.id && <Check className="w-4 h-4" />}
            </div>
            <span className="text-left">{solution.label}</span>
          </button>
        ))}
      </div>

      <div className="flex justify-between">
        <button
          onClick={prevStep}
          className="px-6 py-2 text-gray-600 hover:text-gray-900"
        >
          Geri
        </button>
        <button
          onClick={handleNext}
          className="px-6 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors"
        >
          Devam Et
        </button>
      </div>
    </div>
  );
}