import React from 'react';
import { Check } from 'lucide-react';
import useOfferwallStore, { CompanyNeed } from '../../../store/offerwallStore';

const needs = [
  {
    id: 'talent_search' as CompanyNeed,
    label: 'Açık pozisyonlar için yetenek bulmak',
  },
  {
    id: 'freelance_search' as CompanyNeed,
    label: 'Freelance veya proje bazlı çalışan arayışı',
  },
  {
    id: 'talent_pool' as CompanyNeed,
    label: 'Yetenek havuzu oluşturmak',
  },
  {
    id: 'hr_optimization' as CompanyNeed,
    label: 'İK süreçlerinin optimize edilmesi',
  },
  {
    id: 'consulting' as CompanyNeed,
    label: 'Yalnızca danışmanlık hizmeti almak',
  },
];

export default function NeedsStep() {
  const { formData, updateNeeds, nextStep, prevStep } = useOfferwallStore();
  const [selectedNeeds, setSelectedNeeds] = React.useState<CompanyNeed[]>(formData.needs);

  const toggleNeed = (need: CompanyNeed) => {
    setSelectedNeeds((prev) =>
      prev.includes(need)
        ? prev.filter((n) => n !== need)
        : [...prev, need]
    );
  };

  const handleNext = () => {
    if (selectedNeeds.length > 0) {
      updateNeeds(selectedNeeds);
      nextStep();
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-900 mb-4">
        Öncelikli İhtiyacınız Nedir?
      </h2>
      <p className="text-gray-600 mb-6">
        Size en iyi şekilde yardımcı olabilmemiz için lütfen ihtiyaçlarınızı seçin.
      </p>

      <div className="space-y-3 mb-8">
        {needs.map((need) => (
          <button
            key={need.id}
            onClick={() => toggleNeed(need.id)}
            className={`w-full flex items-center p-4 rounded-lg border-2 transition-colors ${
              selectedNeeds.includes(need.id)
                ? 'border-blue-600 bg-blue-50'
                : 'border-gray-200 hover:border-blue-200'
            }`}
          >
            <div
              className={`w-6 h-6 rounded-full border-2 mr-4 flex items-center justify-center ${
                selectedNeeds.includes(need.id)
                  ? 'border-blue-600 bg-blue-600 text-white'
                  : 'border-gray-300'
              }`}
            >
              {selectedNeeds.includes(need.id) && <Check className="w-4 h-4" />}
            </div>
            <span className="text-left">{need.label}</span>
          </button>
        ))}
      </div>

      <div className="flex justify-between">
        <button
          onClick={prevStep}
          className="px-6 py-2 text-gray-600 hover:text-gray-900"
        >
          Geri
        </button>
        <button
          onClick={handleNext}
          disabled={selectedNeeds.length === 0}
          className="px-6 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Devam Et
        </button>
      </div>
    </div>
  );
}