import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface ContactPerson {
  id: string;
  name: string;
  position: string;
  email: string;
  phone: string;
}

export interface Company {
  id: string;
  name: string;
  description: string;
  industry: string;
  website: string;
  location: string;
  contacts: ContactPerson[];
  createdAt: string;
}

interface CompaniesState {
  companies: Company[];
  addCompany: (company: Omit<Company, 'id' | 'createdAt'>) => void;
  updateCompany: (id: string, company: Partial<Company>) => void;
  deleteCompany: (id: string) => void;
  addContact: (companyId: string, contact: Omit<ContactPerson, 'id'>) => void;
  updateContact: (companyId: string, contactId: string, contact: Partial<ContactPerson>) => void;
  deleteContact: (companyId: string, contactId: string) => void;
}

const generateId = () => `${Date.now().toString(36)}-${Math.random().toString(36).substr(2, 5)}`;

const useCompaniesStore = create<CompaniesState>()(
  persist(
    (set) => ({
      companies: [],
      addCompany: (company) =>
        set((state) => ({
          companies: [
            ...state.companies,
            {
              ...company,
              id: generateId(),
              createdAt: new Date().toISOString(),
            },
          ],
        })),
      updateCompany: (id, updatedCompany) =>
        set((state) => ({
          companies: state.companies.map((company) =>
            company.id === id ? { ...company, ...updatedCompany } : company
          ),
        })),
      deleteCompany: (id) =>
        set((state) => ({
          companies: state.companies.filter((company) => company.id !== id),
        })),
      addContact: (companyId, contact) =>
        set((state) => ({
          companies: state.companies.map((company) =>
            company.id === companyId
              ? {
                  ...company,
                  contacts: [
                    ...company.contacts,
                    { ...contact, id: generateId() },
                  ],
                }
              : company
          ),
        })),
      updateContact: (companyId, contactId, updatedContact) =>
        set((state) => ({
          companies: state.companies.map((company) =>
            company.id === companyId
              ? {
                  ...company,
                  contacts: company.contacts.map((contact) =>
                    contact.id === contactId
                      ? { ...contact, ...updatedContact }
                      : contact
                  ),
                }
              : company
          ),
        })),
      deleteContact: (companyId, contactId) =>
        set((state) => ({
          companies: state.companies.map((company) =>
            company.id === companyId
              ? {
                  ...company,
                  contacts: company.contacts.filter(
                    (contact) => contact.id !== contactId
                  ),
                }
              : company
          ),
        })),
    }),
    {
      name: 'companies-storage',
    }
  )
);

export default useCompaniesStore;