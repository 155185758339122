import React, { useState } from 'react';
import { X } from 'lucide-react';
import { motion } from 'framer-motion';
import useToCallListStore from '../../store/toCallListStore';
import useApplicationsStore from '../../store/applicationsStore';

interface ToCallNotesModalProps {
  itemId: number;
  onClose: () => void;
}

export default function ToCallNotesModal({ itemId, onClose }: ToCallNotesModalProps) {
  const { items, updateNotes } = useToCallListStore();
  const { addMeetingReport } = useApplicationsStore();
  const item = items.find((i) => i.id === itemId);
  const [notes, setNotes] = useState(item?.notes || '');
  const [rating, setRating] = useState(3);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!item) return;

    // Update notes in call list
    updateNotes(itemId, notes);

    // Add meeting report
    addMeetingReport(item.applicationId, {
      date: new Date().toISOString().split('T')[0],
      meetingType: 'phone_call',
      notes: notes,
      rating,
      attendees: [`${item.firstName} ${item.lastName}`]
    });

    onClose();
  };

  if (!item) return null;

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={onClose}
        className="fixed inset-0 bg-black/50 z-50"
      />
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className="fixed inset-0 z-50 flex items-center justify-center p-4"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="bg-white rounded-xl shadow-xl max-w-md w-full">
          <div className="p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold text-gray-900">
                Add Call Notes for {item.firstName} {item.lastName}
              </h2>
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700"
              >
                <X size={24} />
              </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Call Notes
                </label>
                <textarea
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  rows={4}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Add notes about the call..."
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Call Rating (1-5)
                </label>
                <input
                  type="range"
                  min="1"
                  max="5"
                  value={rating}
                  onChange={(e) => setRating(Number(e.target.value))}
                  className="w-full"
                />
                <div className="flex justify-between text-sm text-gray-500">
                  <span>Poor</span>
                  <span>Excellent</span>
                </div>
              </div>

              <p className="text-sm text-gray-500">
                Note: This will be added to meeting reports and saved in the call list.
              </p>

              <button
                type="submit"
                className="w-full bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors"
              >
                Save Notes & Create Report
              </button>
            </form>
          </div>
        </div>
      </motion.div>
    </>
  );
}