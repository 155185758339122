import React, { useState } from 'react';
import { Phone, Trash2, Edit2, Star, Search, FileText } from 'lucide-react';
import useToCallListStore from '../../store/toCallListStore';
import { makePhoneCall } from '../../utils/phoneService';
import ToCallNotesModal from './ToCallNotesModal';
import ApplicantDetailsModal from './ApplicantDetailsModal';

export default function ToCallList() {
  const { items, removeFromList, updatePriority } = useToCallListStore();
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [showDetailsModal, setShowDetailsModal] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDialing, setIsDialing] = useState(false);

  const filteredItems = items.filter(
    (item) =>
      item.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.phone.includes(searchTerm) ||
      item.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.position.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.company.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCall = async (phone: string) => {
    try {
      setIsDialing(true);
      await makePhoneCall(phone);
    } catch (error) {
      alert('Failed to initiate phone call');
    } finally {
      setIsDialing(false);
    }
  };

  const priorityColors = {
    high: 'text-red-600',
    medium: 'text-yellow-600',
    low: 'text-blue-600',
  };

  return (
    <div className="p-6">
      <div className="mb-6">
        <h1 className="text-2xl font-semibold text-gray-900 mb-4">To Call List</h1>
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search by name, position, company..."
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
      </div>

      <div className="space-y-4">
        {filteredItems.length === 0 ? (
          <div className="text-center text-gray-500 py-8">
            No items in the call list
          </div>
        ) : (
          filteredItems.map((item) => (
            <div
              key={item.id}
              className="bg-white border border-gray-200 rounded-lg p-4 hover:shadow-md transition-shadow"
            >
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-lg font-medium text-gray-900">
                    {item.firstName} {item.lastName}
                  </h3>
                  <p className="text-sm text-gray-600">{item.position} at {item.company}</p>
                  <p className="text-sm text-gray-600">{item.email}</p>
                  <p className="text-sm text-gray-600">{item.phone}</p>
                  {item.notes && (
                    <p className="text-sm text-gray-600 mt-2">{item.notes}</p>
                  )}
                </div>
                <div className="flex items-center gap-3">
                  <button
                    onClick={() => {
                      const nextPriority = {
                        high: 'medium',
                        medium: 'low',
                        low: 'high',
                      } as const;
                      updatePriority(item.id, nextPriority[item.priority]);
                    }}
                    className={`${priorityColors[item.priority]}`}
                    title="Toggle Priority"
                  >
                    <Star
                      size={20}
                      className={item.priority === 'high' ? 'fill-current' : ''}
                    />
                  </button>
                  <button
                    onClick={() => setSelectedItem(item.id)}
                    className="text-blue-600 hover:text-blue-700"
                    title="Add Notes"
                  >
                    <Edit2 size={20} />
                  </button>
                  <button
                    onClick={() => setShowDetailsModal(item.applicationId)}
                    className="text-gray-600 hover:text-gray-900"
                    title="View Application Details"
                  >
                    <FileText size={20} />
                  </button>
                  <button
                    onClick={() => handleCall(item.phone)}
                    disabled={isDialing}
                    className="text-green-600 hover:text-green-700 disabled:opacity-50"
                    title="Call"
                  >
                    <Phone size={20} />
                  </button>
                  <button
                    onClick={() => removeFromList(item.id)}
                    className="text-red-600 hover:text-red-700"
                    title="Remove from List"
                  >
                    <Trash2 size={20} />
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      {selectedItem && (
        <ToCallNotesModal
          itemId={selectedItem}
          onClose={() => setSelectedItem(null)}
        />
      )}

      {showDetailsModal && (
        <ApplicantDetailsModal
          isOpen={true}
          onClose={() => setShowDetailsModal(null)}
          applicationId={showDetailsModal}
        />
      )}
    </div>
  );
}