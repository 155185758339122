import React from 'react';
import { useOfferwallSubmission } from '../../../hooks/useOfferwallSubmission';
import useOfferwallStore from '../../../store/offerwallStore';
import StepNavigation from '../StepNavigation';

export default function ContactStep() {
  const { formData, updateContact, nextStep, prevStep } = useOfferwallStore();
  const { submit, isSubmitting, error, clearError } = useOfferwallSubmission();
  const [contact, setContact] = React.useState(formData.contact);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setContact(prev => ({ ...prev, [name]: value }));
    clearError();
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    updateContact(contact);
    
    const success = await submit({
      needs: formData.needs,
      positions: formData.positions,
      solution: formData.solution,
      contact
    });

    if (success) {
      nextStep();
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-900 mb-4">
        Sizi Daha İyi Tanıyalım
      </h2>
      <p className="text-gray-600 mb-6">
        Size en kısa sürede dönüş yapabilmemiz için lütfen iletişim bilgilerinizi paylaşın.
      </p>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Firma Adı
          </label>
          <input
            type="text"
            name="companyName"
            value={contact.companyName}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Ad Soyad
          </label>
          <input
            type="text"
            name="fullName"
            value={contact.fullName}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            E-posta
          </label>
          <input
            type="email"
            name="email"
            value={contact.email}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Telefon
          </label>
          <input
            type="tel"
            name="phone"
            value={contact.phone}
            onChange={handleChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Mesaj (İsteğe bağlı)
          </label>
          <textarea
            name="message"
            value={contact.message}
            onChange={handleChange}
            rows={4}
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        {error && (
          <div className="text-red-600 text-sm">
            {error}
          </div>
        )}

        <StepNavigation
          onBack={prevStep}
          isSubmit={true}
          isLoading={isSubmitting}
          nextLabel="Gönder"
        />
      </form>
    </div>
  );
}