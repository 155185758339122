export function getErrorMessage(error: unknown): string {
  if (error instanceof Error) {
    return error.message;
  }
  
  if (typeof error === 'object' && error !== null) {
    const { message, error: errorDetails } = error as any;
    return message || errorDetails?.message || 'Beklenmeyen bir hata oluştu';
  }
  
  return 'Beklenmeyen bir hata oluştu';
}

export function logError(context: string, error: unknown) {
  console.error(`Error in ${context}:`, {
    error,
    timestamp: new Date().toISOString(),
    details: error instanceof Error ? {
      message: error.message,
      stack: error.stack,
    } : error
  });
}