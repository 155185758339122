import { create } from 'zustand';

export type CompanyNeed = 
  | 'talent_search'
  | 'freelance_search'
  | 'talent_pool'
  | 'hr_optimization'
  | 'consulting';

export type EmploymentType = 'full_time' | 'freelance' | 'hybrid';

export type SolutionType = 
  | 'full_process'
  | 'candidate_pool'
  | 'cv_screening'
  | 'custom';

interface OfferwallState {
  currentStep: number;
  formData: {
    needs: CompanyNeed[];
    positions: {
      count: number;
      types: string[];
      employmentType: EmploymentType[];
    };
    solution: SolutionType;
    contact: {
      companyName: string;
      fullName: string;
      email: string;
      phone: string;
      message?: string;
    };
  };
  nextStep: () => void;
  prevStep: () => void;
  updateNeeds: (needs: CompanyNeed[]) => void;
  updatePositions: (positions: typeof OfferwallState.prototype.formData.positions) => void;
  updateSolution: (solution: SolutionType) => void;
  updateContact: (contact: typeof OfferwallState.prototype.formData.contact) => void;
  resetForm: () => void;
}

const initialFormData = {
  needs: [],
  positions: {
    count: 1,
    types: [],
    employmentType: [],
  },
  solution: 'full_process' as SolutionType,
  contact: {
    companyName: '',
    fullName: '',
    email: '',
    phone: '',
    message: '',
  },
};

const useOfferwallStore = create<OfferwallState>((set) => ({
  currentStep: 1,
  formData: initialFormData,
  nextStep: () => set((state) => ({ currentStep: state.currentStep + 1 })),
  prevStep: () => set((state) => ({ currentStep: Math.max(1, state.currentStep - 1) })),
  updateNeeds: (needs) => set((state) => ({ formData: { ...state.formData, needs } })),
  updatePositions: (positions) => set((state) => ({ formData: { ...state.formData, positions } })),
  updateSolution: (solution) => set((state) => ({ formData: { ...state.formData, solution } })),
  updateContact: (contact) => set((state) => ({ formData: { ...state.formData, contact } })),
  resetForm: () => set({ currentStep: 1, formData: initialFormData }),
}));

export default useOfferwallStore;