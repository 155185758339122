import React from 'react';
import { motion } from 'framer-motion';
import { Clock, Users, CheckCircle } from 'lucide-react';

const timelineItems = [
  {
    icon: Clock,
    title: 'Zaman Tasarrufu',
    description: 'İşe alım süreçlerinizi %60 hızlandırın',
  },
  {
    icon: Users,
    title: 'Doğru Eşleşme',
    description: 'En uygun adaylarla %95 eşleşme oranı',
  },
  {
    icon: CheckCircle,
    title: 'Kolay Süreç',
    description: 'Sadece 5 dakikada tamamlayın',
  },
];

export default function TimelineProgress() {
  return (
    <div className="grid md:grid-cols-3 gap-8 mb-12">
      {timelineItems.map((item, index) => (
        <motion.div
          key={item.title}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.2 }}
          className="flex flex-col items-center text-center"
        >
          <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-4">
            <item.icon className="w-8 h-8 text-blue-600" />
          </div>
          <h3 className="text-lg font-semibold text-gray-900 mb-2">
            {item.title}
          </h3>
          <p className="text-gray-600">{item.description}</p>
        </motion.div>
      ))}
    </div>
  );
}