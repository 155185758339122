import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Globe } from 'lucide-react';

export default function LanguageSelector() {
  const navigate = useNavigate();
  const location = useLocation();
  
  const languages = [
    { code: 'tr', name: 'Türkçe' },
    { code: 'en', name: 'English' },
    { code: 'fi', name: 'Suomi' }
  ];

  const currentLang = location.pathname.split('/').pop() || 'tr';

  const handleLanguageChange = (code: string) => {
    navigate(`/one-pager/${code}`, { replace: true });
  };

  return (
    <div className="relative group">
      <button className="flex items-center gap-2 text-gray-600 hover:text-blue-600 transition-colors bg-white/80 backdrop-blur-sm px-4 py-2 rounded-lg">
        <Globe className="w-5 h-5" />
        <span>{languages.find(lang => lang.code === currentLang)?.name || 'Türkçe'}</span>
      </button>
      
      <div className="absolute right-0 mt-2 py-2 w-48 bg-white rounded-lg shadow-xl opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all z-50">
        {languages.map((lang) => (
          <button
            key={lang.code}
            onClick={() => handleLanguageChange(lang.code)}
            className={`block w-full text-left px-4 py-2 text-sm hover:bg-gray-100 transition-colors ${
              currentLang === lang.code ? 'text-blue-600 font-medium' : 'text-gray-700'
            }`}
          >
            {lang.name}
          </button>
        ))}
      </div>
    </div>
  );
}