import emailjs from '@emailjs/browser';

// Initialize EmailJS with your public key
emailjs.init('TlW55K_Vg0XRa07eU');

interface EmailData {
  name: string;
  email: string;
  message: string;
  subject: string;
  [key: string]: string;
}

export const sendEmail = async (data: EmailData, templateId: string) => {
  try {
    const response = await emailjs.send(
      'service_2rhclfy',
      templateId,
      {
        from_name: data.name,
        from_email: data.email,
        message: data.message,
        subject: data.subject,
        to_name: 'iTalentHunt Team',
        to_email: 'ozgylbasakkalkan@gmail.com',
        ...data,
      }
    );

    if (response.status !== 200) {
      throw new Error('Failed to send email');
    }

    return response;
  } catch (error) {
    console.error('Email sending failed:', error);
    throw error;
  }
};