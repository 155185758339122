import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { Button } from '../ui/Button';

interface StepNavigationProps {
  onBack?: () => void;
  onNext?: () => void;
  nextLabel?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  isSubmit?: boolean;
}

export default function StepNavigation({
  onBack,
  onNext,
  nextLabel = 'Devam Et',
  isLoading = false,
  isDisabled = false,
  isSubmit = false,
}: StepNavigationProps) {
  return (
    <div className="flex justify-between items-center pt-6">
      {onBack && (
        <Button
          type="button"
          variant="outline"
          onClick={onBack}
          disabled={isLoading}
          icon={<ArrowLeft className="w-4 h-4" />}
        >
          Geri
        </Button>
      )}
      {(onNext || isSubmit) && (
        <Button
          type={isSubmit ? 'submit' : 'button'}
          onClick={isSubmit ? undefined : onNext}
          disabled={isLoading || isDisabled}
          isLoading={isLoading}
          className="ml-auto"
        >
          {nextLabel}
        </Button>
      )}
    </div>
  );
}