import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { MessageSquare } from 'lucide-react';

interface Testimonial {
  id: number;
  text: string;
  author: string;
  position: string;
  company: string;
}

interface RotatingTestimonialsProps {
  testimonials: Testimonial[];
}

export default function RotatingTestimonials({ testimonials }: RotatingTestimonialsProps) {
  const [[page, direction], setPage] = useState([0, 0]);

  useEffect(() => {
    const timer = setInterval(() => {
      paginate(1);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  const paginate = (newDirection: number) => {
    setPage(([currentPage]) => {
      const nextPage = currentPage + newDirection;
      if (nextPage >= testimonials.length) return [0, newDirection];
      if (nextPage < 0) return [testimonials.length - 1, newDirection];
      return [nextPage, newDirection];
    });
  };

  return (
    <div className="relative">
      <div className="overflow-hidden">
        <AnimatePresence mode="wait" initial={false} custom={direction}>
          <motion.div
            key={page}
            custom={direction}
            initial={{ opacity: 0, x: direction > 0 ? 200 : -200 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: direction > 0 ? -200 : 200 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
            className="bg-white p-8 rounded-xl shadow-lg"
          >
            <MessageSquare className="w-12 h-12 text-blue-600 mx-auto mb-6" />
            <p className="text-xl text-gray-600 italic mb-6">
              {testimonials[page].text}
            </p>
            <div>
              <p className="font-semibold">{testimonials[page].author}</p>
              <p className="text-gray-600">
                {testimonials[page].position}
              </p>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>

      <div className="flex justify-center mt-6 space-x-2">
        {testimonials.map((_, index) => (
          <button
            key={index}
            onClick={() => {
              const newDirection = index > page ? 1 : -1;
              setPage([index, newDirection]);
            }}
            className={`w-2 h-2 rounded-full transition-colors ${
              index === page ? 'bg-blue-600' : 'bg-gray-300'
            }`}
          />
        ))}
      </div>
    </div>
  );
}