import { fileOpen, supported } from 'browser-fs-access';

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

const validateFile = (file: File) => {
  const validTypes = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];

  if (!validTypes.includes(file.type)) {
    throw new Error('Invalid file type. Please upload a PDF, DOC, or DOCX file.');
  }

  if (file.size > MAX_FILE_SIZE) {
    throw new Error('File size exceeds 10MB limit');
  }

  return true;
};

const readFileAsDataURL = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject(new Error('Failed to read file data'));
      }
    };
    reader.onerror = () => reject(new Error('Failed to read file'));
    reader.readAsDataURL(file);
  });
};

export async function openCV(fileInputRef?: React.RefObject<HTMLInputElement>): Promise<{ name: string; data: string; type: string }> {
  try {
    let file: File;

    if (supported && !fileInputRef) {
      file = await fileOpen({
        mimeTypes: [
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        ],
        extensions: ['.pdf', '.doc', '.docx'],
      });
    } else if (fileInputRef?.current?.files?.[0]) {
      file = fileInputRef.current.files[0];
    } else {
      throw new Error('No file selected');
    }

    validateFile(file);
    const data = await readFileAsDataURL(file);

    return {
      name: file.name,
      data,
      type: file.type,
    };
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    }
    throw new Error('Failed to upload file');
  }
}

export function downloadCV(cv: { name: string; data: string; type: string }) {
  try {
    const link = document.createElement('a');
    link.href = cv.data;
    link.download = cv.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error('Error downloading CV:', error);
    throw new Error('Failed to download file');
  }
}