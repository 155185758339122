import React from 'react';
import { motion } from 'framer-motion';
import { Building2, Users, BriefcaseIcon, Settings, UserCircle, CheckCircle } from 'lucide-react';

export const steps = [
  { 
    id: 1, 
    icon: Building2,
    label: 'Başlangıç'
  },
  { 
    id: 2, 
    icon: Users,
    label: 'İhtiyaçlar'
  },
  { 
    id: 3, 
    icon: BriefcaseIcon,
    label: 'Pozisyonlar'
  },
  { 
    id: 4, 
    icon: Settings,
    label: 'Çözüm'
  },
  { 
    id: 5, 
    icon: UserCircle,
    label: 'İletişim'
  },
  { 
    id: 6, 
    icon: CheckCircle,
    label: 'Tamamlandı'
  },
];

interface ProgressBarProps {
  currentStep: number;
}

export default function ProgressBar({ currentStep }: ProgressBarProps) {
  return (
    <div className="flex justify-between items-center relative">
      {/* Progress Line */}
      <div className="absolute top-1/2 left-0 w-full h-1 bg-gray-200 -translate-y-1/2">
        <motion.div
          className="h-full bg-blue-600 origin-left"
          initial={{ scaleX: 0 }}
          animate={{ scaleX: (currentStep - 1) / (steps.length - 1) }}
          transition={{ duration: 0.3 }}
        />
      </div>

      {/* Step Indicators */}
      {steps.map((step) => (
        <div key={step.id} className="relative z-10">
          <motion.div
            initial={false}
            animate={{
              scale: step.id <= currentStep ? 1 : 0.8,
              opacity: step.id <= currentStep ? 1 : 0.5,
            }}
            className="flex flex-col items-center"
          >
            <div
              className={`w-12 h-12 rounded-full flex items-center justify-center transition-colors ${
                step.id < currentStep
                  ? 'bg-blue-600 text-white'
                  : step.id === currentStep
                  ? 'bg-blue-600 text-white ring-4 ring-blue-100'
                  : 'bg-white border-2 border-gray-200'
              }`}
            >
              <step.icon className="w-6 h-6" />
            </div>
            <span className={`mt-2 text-sm font-medium ${
              step.id <= currentStep ? 'text-blue-600' : 'text-gray-400'
            }`}>
              {step.label}
            </span>
          </motion.div>
        </div>
      ))}
    </div>
  );
}