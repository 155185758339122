const API_URL = process.env.NODE_ENV === 'production' 
  ? 'https://italenthunt.com'
  : 'http://localhost:3000';

const WS_URL = process.env.NODE_ENV === 'production'
  ? 'wss://italenthunt.com'
  : 'ws://localhost:3000';

export const makePhoneCall = async (phoneNumber: string) => {
  try {
    // Format phone number (remove spaces, dashes etc)
    const formattedNumber = phoneNumber.replace(/[^0-9+]/g, '');
    
    const response = await fetch(`${API_URL}/api/call`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ 
        phoneNumber: formattedNumber,
        timestamp: new Date().toISOString()
      })
    });

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Failed to send call request');
    }

    const result = await response.json();
    console.log('Call request sent:', result);

    return result;
  } catch (error) {
    console.error('Failed to initiate phone call:', error);
    throw new Error('Failed to initiate phone call. Please try again later.');
  }
};

export { WS_URL };